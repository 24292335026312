import { render, staticRenderFns } from "./HistoryTable.vue?vue&type=template&id=3d6f5922"
import script from "./HistoryTable.vue?vue&type=script&lang=js"
export * from "./HistoryTable.vue?vue&type=script&lang=js"
import style0 from "./HistoryTable.vue?vue&type=style&index=0&id=3d6f5922&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VAlert,VIcon,VProgressCircular})

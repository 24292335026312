<template>
  <div class="mb-2">
    <v-card class="new-element-border-radius" outlined>
      <div
        :class="notEditable ? '' : 'clickable'"
        :v-ripple="!notEditable"
        @click="changeCheckboxValue"
      >
        <v-list-item three-line>
          <v-avatar
            class="mr-5"
            style="cursor: pointer"
            color="#6E6E6E"
            size="30"
          >
            <span class="white--text headline">{{ indexData }}</span>
          </v-avatar>
          <v-list-item-content
            :class="
              $vuetify.breakpoint.xs
                ? 'checkbox_title_xs'
                : 'checkbox_title_default'
            "
          >
            <div class="flex-row-gap">
              <div>{{ $t(title) }}</div>
              <div v-if="propDate" class="prop-date-text">
                <div @click.stop="toggleDatePicker()">
                  <input
                    type="text"
                    class="date-input"
                    readonly
                    :value="formatDate(propDate)"
                    placeholder=""
                    ref="inputPicker"
                  />
                </div>
                <div
                  v-if="isPickerVisible"
                  class="date-picker-overlay"
                  ref="tyalordatePicker"
                  @click.stop
                >
                  <div class="date-picker-content">
                    <v-date-picker
                      :value="propDate"
                      @input="onChangeDate"
                      color="#9b004b"
                      no-title
                      scrollable
                    ></v-date-picker>
                  </div>
                </div>
              </div>
            </div>
          </v-list-item-content>
          <v-list-item-action
            class="ma-0"
            style="align-self: center"
            @keydown.space="changeCheckboxValue"
          >
            <v-checkbox
              color="#9B004B"
              v-model="checkboxState"
              :disabled="notEditable"
              @click.stop=""
            >
            </v-checkbox>
          </v-list-item-action>
        </v-list-item>
      </div>
    </v-card>
    <div class="mb-2" v-if="showError && !checkboxState" style="color: #c20016">
      {{ $t(requiredText) }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'DateCheckboxComponent',
  props: [
    'title',
    'checkboxName',
    'showError',
    'index',
    'requiredText',
    'indexData',
    'notEditable',
    'propDate',
    'onInput',
    'energyType',
  ],
  data() {
    return {
      isPickerVisible: false,
    };
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  computed: {
    checkboxState: {
      get() {
        const result =
          this.$store.getters['get' + this.checkboxName + 'Selected'];
        return result;
      },
      set(value) {
        this.$store.commit('set' + this.checkboxName + 'Selected', value);
        this.$emit('update-checkbox-value', value);
      },
    },
  },
  methods: {
    onChangeDate(date) {
      if (this.onInput) {
        this.onInput(date, this.energyType);
      }
      this.toggleDatePicker();
    },
    formatDate(date) {
      if (!date) return '';
      return new Date(date).toLocaleDateString('de-DE', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
    },
    handleClickOutside(event) {
      const picker = this.$refs.tyalordatePicker;
      const inputPicker = this.$refs.inputPicker;
      if (!picker) {
        return;
      }

      const clickedOutsidePicker = picker && !picker.contains(event.target);

      const didNotClickOnInputPicker =
        inputPicker && !inputPicker.contains(event.target);

      if (clickedOutsidePicker && didNotClickOnInputPicker) {
        this.isPickerVisible = false;
      }
    },
    changeCheckboxValue() {
      if (!this.notEditable) {
        this.checkboxState = !this.checkboxState;
      }
    },
    toggleDatePicker() {
      this.isPickerVisible = !this.isPickerVisible;
    },
  },
};
</script>

<style>
.date-picker-overlay {
  position: absolute;
  bottom: 100%;
  left: 0;
  z-index: 1000;
  margin-top: 5px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.date-picker-content {
  padding: 15px;
}

.clickable {
  cursor: pointer;
  box-shadow: 0px 1px 2px #00000029;
  position: relative;
}

.checkbox_title_default {
  font-size: 20px;
  line-height: 24px;
}
.checkbox_title_xs {
  font-size: 18px;
  line-height: 21px;
}
.prop-date-text {
  font-size: 14px;
  color: #6e6e6e;
  margin-top: 4px;
}
.date-input {
  width: auto;
  width: 90px;
  padding: 4px 5px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 14px;
}

.flex-row-gap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 24px;
  align-items: center;
}
</style>

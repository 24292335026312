<template>
  <div>
    <v-row style="justify-content: flex-end; margin-right: 0px"> </v-row>
    <single-raw
      first
      class="single-raw"
      :title="$t('installer_details.date')"
      :editable="false"
      >{{ formatDate(selectedInstaller.request_date) }}</single-raw
    >
    <single-raw
      class="single-raw"
      :title="$t('installer_details.company_data')"
      @edit="
        $emit('show-modal', {
          component: 'InstallerCompanyDataComponent',
          method: 'updateInstallerCompanyData',
        })
      "
    >
      <div>{{ selectedInstaller.company_data.company_name }}</div>
      <div>{{ selectedInstallerAddress }}</div>
      <div>Telefon: {{ selectedInstaller.company_data.phone_number }}</div>
      <div>
        Entstörrufnummer:
        {{
          selectedInstaller.company_data.troubleshooting_number
            ? selectedInstaller.company_data.troubleshooting_number
            : 'nicht angegeben'
        }}
      </div>
      <div>E-Mail: {{ selectedInstaller.company_data.email }}</div>
      <userfeedback-component
        v-if="notifyEvent.field === 'updateInstallerCompanyData'"
      />
    </single-raw>

    <single-raw
      class="single-raw"
      :title="$t('installer_details.responsible_specialist')"
      @edit="
        $emit('show-modal', {
          component: 'ResponisibleSpecialistsStepperComponent',
          method: 'updateInstallerPeople',
        })
      "
    >
      <div>
        <div
          v-for="(item, index) in selectedInstaller.responsible_specialists"
          :key="`${item.name}${index}`"
        >
          <div>
            {{ item.name }} {{ item.surname }} ({{ item.function_in_company }})
          </div>
          <div>
            Fachkraft für: {{ transformConcessionDivision(item.energy_types) }}
          </div>
          <div>
            Telefon:
            {{ item.phone_number ? item.phone_number : 'nicht angegeben' }}
          </div>
          <div>
            Mobilnummer:
            {{ item.mobile_number ? item.mobile_number : 'nicht angegeben' }}
          </div>
          <div>Email: {{ item.email ? item.email : 'nicht angegeben' }}</div>
          <div
            v-if="
              index !== selectedInstaller.responsible_specialists.length - 1
            "
          >
            <br />
          </div>
        </div>
        <userfeedback-component
          v-if="notifyEvent.field === 'updateInstallerPeople'"
        />
      </div>
    </single-raw>

    <single-raw
      class="single-raw"
      :title="$t('installer_details.contact_person')"
      @edit="
        $emit('show-modal', {
          component: 'InstallerContactPersonComponent',
          method: 'updateInstallerContactPerson',
        })
      "
    >
      <div v-if="selectedInstaller.contact_person">
        <div>
          {{ selectedInstaller.contact_person.title }}
          {{ selectedInstaller.contact_person.name }}
          {{ selectedInstaller.contact_person.surname }} ({{
            selectedInstaller.contact_person.function_in_company
          }})
        </div>
        <div>
          Telefon:
          {{
            selectedInstaller.contact_person.phone_number
              ? selectedInstaller.contact_person.phone_number
              : 'nicht angegeben'
          }}
        </div>
        <div>
          Mobilnummer:
          {{
            selectedInstaller.contact_person.mobile_number
              ? selectedInstaller.contact_person.mobile_number
              : 'nicht angegeben'
          }}
        </div>
        <div>
          Email:
          {{
            selectedInstaller.contact_person.email
              ? selectedInstaller.contact_person.email
              : 'nicht angegeben'
          }}
        </div>
      </div>
      <div v-else>
        <div>-</div>
      </div>
      <userfeedback-component
        v-if="notifyEvent.field === 'updateInstallerContactPerson'"
      />
    </single-raw>

    <single-raw
      class="single-raw"
      :title="$t('installer_details.concession_division')"
      @edit="
        $emit('show-modal', {
          component: 'InstallerConcessionDivisionComponent',
          method: 'updateInstallerConcessionDivision',
        })
      "
    >
      <div>
        {{ transformConcessionDivision(selectedInstaller.concession_division) }}
      </div>
      <userfeedback-component
        v-if="notifyEvent.field === 'updateInstallerConcessionDivision'"
      />
    </single-raw>
    <div v-if="selectedInstaller.internal">
      <InstallerFileRow
        v-for="(group, index) in groupedFiles"
        :key="`${group.title}-${index}`"
        :class="group.class"
        :title="group.title"
        :filesArray="group.files"
        @edit="
          $emit('show-modal', {
            component: 'InstallerFileUploadComponent',
            method: 'updateInstallerFilesName',
            properties: { file_data: $event },
          })
        "
      >
      </InstallerFileRow>
      <InstallerFileRow
        v-for="(group, index) in groupedEvidenceFiles"
        :key="`${group.title}-${index}`"
        :class="group.class"
        :title="group.title"
        :filesArray="group.files"
        @edit="
          $emit('show-modal', {
            component: 'InstallerFileUploadComponent',
            method: 'updateInstallerFilesName',
            properties: { file_data: $event },
          })
        "
      >
      </InstallerFileRow>
    </div>
    <div v-if="!selectedInstaller.internal">
      <InstallerFileRow
        class="single-raw"
        :title="$t('installer_details.professional_proof')"
        last
        :filesArray="selectedFiles"
        @edit="
          $emit('show-modal', {
            component: 'InstallerFileUploadComponent',
            method: 'updateInstallerFilesName',
            properties: { file_data: $event },
          })
        "
      >
        <userfeedback-component
          v-if="notifyEvent.field === 'updateInstallerFilesName'"
        />
      </InstallerFileRow>
    </div>
    <userfeedback-component
      v-if="notifyEvent.field === 'updateInstallerFilesName'"
    />
  </div>
</template>

<script>
import SingleRaw from '../order-details/SingleRaw';
import InstallerFileRow from './InstallerFileRow';
import documentsService from '../../services/DocumentsService';
import axios from 'axios';
import { emptyEventData } from '../../store/ordersModule';
import fileMethods from '../../helpers/fileMethods';
import { EventBus } from '../../main';
import { getFilesConfig } from '../../helpers/FilesConfig';
import UserfeedbackComponent from '../emobility-details/UserfeedbackComponent.vue';

export default {
  name: 'InstallerDetails',
  components: {
    SingleRaw,
    InstallerFileRow,
    UserfeedbackComponent,
  },
  props: {
    file_names: {
      type: Object,
    },
  },
  data() {
    return {
      responsibleSpecialistArray: [],
      selectedFiles: [],
    };
  },
  created() {
    for (const [key, value] of Object.entries(this.file_names)) {
      if (value) {
        this.getInstallerDocument(value, key);
      }
    }
    this.selectedFiles = this.getSelectedFiles();
  },
  computed: {
    selectedInstaller() {
      return this.$store.getters.getSelectedInstaller;
    },
    installerFiles() {
      return this.$store.getters.getSelectedInstaller.file_names;
    },
    numberOfResponsibleSpecialists() {
      return this.$store.getters.getSelectedInstaller.responsible_specialists
        .length;
    },
    selectedInstallerAddress() {
      return this.$store.getters.getSelectedInstaller.company_data
        .company_address
        ? this.$store.getters.getSelectedInstaller.company_data.company_address
            .street +
            ' ' +
            this.$store.getters.getSelectedInstaller.company_data
              .company_address.street_number +
            ', ' +
            this.$store.getters.getSelectedInstaller.company_data
              .company_address.zip +
            ' ' +
            this.$store.getters.getSelectedInstaller.company_data
              .company_address.city
        : '';
    },
    notifyEvent() {
      return this.$store.getters.getNotifyEvent
        ? this.$store.getters.getNotifyEvent
        : emptyEventData;
    },
    groupedFiles() {
      return [
        {
          title: this.$t('installer_details.documents'),
          files: this.documentsSectionFiles,
        },
        {
          title: this.$t('installer_details.contracts_and_evidence'),
          files: this.contractsAndEvidenceSectionFiles,
        },
        {
          title: this.$t('installer_details.certificates'),
          files: this.certificatesSectionFiles,
          class: 'last-row-of-section',
        },
      ];
    },
    groupedEvidenceFiles() {
      const files = [];
      if (this.selectedInstaller.concession_division.includes('electricity')) {
        files.push({
          title: this.$t(
            'installer_details.electricity_installation_certificates'
          ),
          files: this.electricitySectionFiles,
          class: `first-row ${
            !this.selectedInstaller.concession_division.includes('gas') &&
            !this.selectedInstaller.concession_division.includes('water')
              ? 'last-row-of-section'
              : ''
          }`,
        });
      }

      if (
        this.selectedInstaller.concession_division.includes('gas') ||
        this.selectedInstaller.concession_division.includes('water')
      ) {
        files.push({
          title: this.$t(
            'installer_details.gas_and_water_installation_certificates'
          ),
          files: this.gasWaterSectionFiles,
          class: `last-row ${
            !this.selectedInstaller.concession_division.includes('electricity')
              ? 'first-row'
              : ''
          }`,
        });
      }

      return files;
    },
    documentsSectionFiles() {
      return this.selectedFiles.filter((file) =>
        [
          'craft_role',
          'business_registration',
          'commercial_register',
          'public_liability_insurance',
        ].includes(file.type)
      );
    },

    contractsAndEvidenceSectionFiles() {
      return this.selectedFiles.filter((file) =>
        [
          'employment_contract',
          'master_certificate_1',
          'master_certificate_2',
          'master_certificate_3',
          'master_certificate_4',
          'master_certificate_5',
        ].includes(file.type)
      );
    },

    certificatesSectionFiles() {
      return this.selectedFiles.filter((file) =>
        [
          'water_technical_certificate_1',
          'water_technical_certificate_2',
          'water_technical_certificate_3',
          'water_technical_certificate_4',
          'water_technical_certificate_5',
          'electricity_technical_certificate_1',
          'electricity_technical_certificate_2',
          'electricity_technical_certificate_3',
          'electricity_technical_certificate_4',
          'electricity_technical_certificate_5',
          'gas_technical_certificate_1',
          'gas_technical_certificate_2',
          'gas_technical_certificate_3',
          'gas_technical_certificate_4',
          'gas_technical_certificate_5',
        ].includes(file.type)
      );
    },

    electricitySectionFiles() {
      return this.selectedFiles.filter(
        (file) => file.type === 'electricity_file_name'
      );
    },

    gasWaterSectionFiles() {
      return this.selectedFiles.filter(
        (file) => file.type === 'gas_water_file_name'
      );
    },
  },
  watch: {
    notifyEvent(newValue) {
      if (newValue !== null && newValue.field.includes('Installer')) {
        setTimeout(() => {
          this.$store.commit('setNotifyEvent', emptyEventData);
        }, 3000);
      }
    },
    numberOfResponsibleSpecialists() {
      if (this.selectedInstaller.internal) {
        this.selectedFiles = this.getSelectedFiles();
      }
    },
    installerFiles() {
      this.selectedFiles = this.getSelectedFiles();
    },
    'selectedInstaller.concession_division'() {
      this.selectedFiles = this.getSelectedFiles();
    },
  },
  methods: {
    formatDate(date) {
      return this.$dayjs(new Date(date)).format('DD.MM.YYYY HH:mm');
    },
    getSelectedFiles() {
      const files = JSON.parse(
        JSON.stringify(this.selectedInstaller.file_names)
      );
      const fileArray = getFilesConfig(
        this.selectedInstaller.internal ? 'internal' : 'external'
      );
      if (this.selectedInstaller.internal) {
        // add config for responsible specialist
        for (let i = 0; i < this.numberOfResponsibleSpecialists; i++) {
          fileArray.push({
            file: '',
            file_name: '',
            position: i + 6,
            type: 'master_certificate_' + (i + 1),
            required: true,
          });
        }

        // add config for water technical certificates, if applicable.
        for (let i = 0; i < this.numberOfResponsibleSpecialists; i++) {
          if (
            this.selectedInstaller.responsible_specialists[
              i
            ].energy_types.includes('water')
          ) {
            fileArray.push({
              file: '',
              file_name: '',
              position: 11 + i,
              type: 'water_technical_certificate_' + (i + 1),
              required: true,
            });
          }
          if (
            this.selectedInstaller.responsible_specialists[
              i
            ].energy_types.includes('electricity')
          ) {
            fileArray.push({
              file: '',
              file_name: '',
              position: 31 + i,
              type: 'electricity_technical_certificate_' + (i + 1),
              required: true,
            });
          }
          if (
            this.selectedInstaller.responsible_specialists[
              i
            ].energy_types.includes('gas')
          ) {
            fileArray.push({
              file: '',
              file_name: '',
              position: 36 + i,
              type: 'gas_technical_certificate_' + (i + 1),
              required: true,
            });
          }
        }
        // add config for measuring devices
        if (
          this.selectedInstaller.concession_division.includes('electricity')
        ) {
          fileArray.push({
            file: '',
            file_name: '',
            position: 'emg',
            type: 'electricity_file_name',
            required: true,
          });
        }
        if (
          this.selectedInstaller.concession_division.includes('gas') ||
          this.selectedInstaller.concession_division.includes('water')
        ) {
          fileArray.push({
            file: '',
            file_name: '',
            position: 'gwmg',
            type: 'gas_water_file_name',
            required: true,
          });
        }
      }
      // set existing file names to config array
      fileArray.forEach((elementArray) => {
        Object.keys(files).forEach((file) => {
          if (elementArray.type === file) {
            elementArray.file_name = files[file];
          }
        });
      });
      return fileArray;
    },
    getInstallerDocument(file_name, file_type) {
      documentsService
        .getInstallerDocumentGraphQL(
          this.selectedInstaller.id + '/' + file_name,
          process.env.VUE_APP_STAGE
        )
        .then(async (res) => {
          this.downloadFromPresignedURL(res.downloadFile, file_name, file_type);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async downloadFromPresignedURL(fileURL, file_name, file_type) {
      const downloadResponse = await axios({
        url: fileURL,
        method: 'GET',
        headers: {
          Accept: '*',
          'Content-Type': 'application/pdf;charset=utf-8',
        },
        responseType: 'arraybuffer',
        data: {},
      });
      const product = 'installer';
      const id =
        this.selectedInstaller.id +
        '_' +
        file_name.substring(0, file_name.indexOf('_'));
      const fileObject = fileMethods.convertToBlobAndReturnFileObject(
        downloadResponse.data,
        file_name,
        product,
        id,
        file_type
      );
      this.$store.commit('setInstallerFileURL', fileObject);
    },
    transformConcessionDivision(value) {
      let concession_divison = '';
      for (const item of value) {
        switch (item) {
          case 'gas':
            concession_divison += 'Erdgas, ';
            break;
          case 'water':
            concession_divison += 'Trinkwasser, ';
            break;
          case 'electricity':
            concession_divison += 'Strom, ';
            break;
        }
      }
      if (concession_divison.endsWith(', ')) {
        concession_divison = concession_divison.substr(
          0,
          concession_divison.length - 2
        );
      }
      return concession_divison;
    },
  },
  destroyed() {
    EventBus.$off('all-installer-files-uploaded', this.fileUploadDone);
  },
};
</script>

<style>
.single-raw {
  margin: 0 0 1px 0;
}
.professional_proof {
  font-weight: bold;
}
</style>

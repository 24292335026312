import { render, staticRenderFns } from "./TechnicalDetails.vue?vue&type=template&id=83d14928&scoped=true"
import script from "./TechnicalDetails.vue?vue&type=script&lang=js"
export * from "./TechnicalDetails.vue?vue&type=script&lang=js"
import style0 from "./TechnicalDetails.vue?vue&type=style&index=0&id=83d14928&prod&scoped=true&lang=css"
import style1 from "./TechnicalDetails.vue?vue&type=style&index=1&id=83d14928&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "83d14928",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VTooltip})

export const installerInspectionModule = {
  state: {
    company_data_checked_electricity: false,
    responsible_specialists_checked_electricity: false,
    documents_checked_electricity: false,
    professional_competence_checked_electricity: false,
    company_data_checked_gas_water: false,
    responsible_specialists_checked_gas_water: false,
    documents_checked_gas_water: false,
    professional_competence_checked_gas_water: false,
    installer_id_checked_electricity: false,
    installer_id_checked_gas_water: false,
    expiry_date_electricity: '',
    expiry_date_electricity_selected: false,
    expiry_date_water: '',
    expiry_date_water_selected: false,
    expiry_date_gas: '',
    expiry_date_gas_selected: false,
    status: '',
  },
  mutations: {
    setCompanyDataElectricitySelected(state, company_data_checked_electricity) {
      state.company_data_checked_electricity = company_data_checked_electricity;
    },
    setInstallerIDElectricitySelected(state, installer_id_checked_electricity) {
      state.installer_id_checked_electricity = installer_id_checked_electricity;
    },
    setExpiryDateElectricityValue(state, expiry_date_electricity) {
      state.expiry_date_electricity = expiry_date_electricity;
    },
    setResponsibleSpecialistsElectricitySelected(
      state,
      responsible_specialists_checked_electricity
    ) {
      state.responsible_specialists_checked_electricity =
        responsible_specialists_checked_electricity;
    },
    setDocumentsElectricitySelected(state, documents_checked_electricity) {
      state.documents_checked_electricity = documents_checked_electricity;
    },
    setProfessionalCompetenceElectricitySelected(
      state,
      professional_competence_checked_electricity
    ) {
      state.professional_competence_checked_electricity =
        professional_competence_checked_electricity;
    },
    setExpiryDateElectricitySelected(state, expiry_date_electricity_selected) {
      state.expiry_date_electricity_selected = expiry_date_electricity_selected;
    },
    setCompanyDataGasWaterSelected(state, company_data_checked_gas_water) {
      state.company_data_checked_gas_water = company_data_checked_gas_water;
    },
    setInstallerIDGasWaterSelected(state, installer_id_checked_gas_water) {
      state.installer_id_checked_gas_water = installer_id_checked_gas_water;
    },
    setResponsibleSpecialistsGasWaterSelected(
      state,
      responsible_specialists_checked_gas_water
    ) {
      state.responsible_specialists_checked_gas_water =
        responsible_specialists_checked_gas_water;
    },
    setDocumentsGasWaterSelected(state, documents_checked_gas_water) {
      state.documents_checked_gas_water = documents_checked_gas_water;
    },
    setProfessionalCompetenceGasWaterSelected(
      state,
      professional_competence_checked_gas_water
    ) {
      state.professional_competence_checked_gas_water =
        professional_competence_checked_gas_water;
    },
    setExpiryDateGasValue(state, expiry_date_gas) {
      state.expiry_date_gas = expiry_date_gas;
    },
    setExpiryDateGasSelected(state, expiry_date_gas_selected) {
      state.expiry_date_gas_selected = expiry_date_gas_selected;
    },
    setExpiryDateWaterValue(state, expiry_date_water) {
      state.expiry_date_water = expiry_date_water;
    },
    setExpiryDateWaterSelected(state, expiry_date_water_selected) {
      state.expiry_date_water_selected = expiry_date_water_selected;
    },
    resetChecksForInstallerInspection(state) {
      (state.company_data_checked_electricity = false),
        (state.responsible_specialists_checked_electricity = false),
        (state.documents_checked_electricity = false),
        (state.professional_competence_checked_electricity = false),
        (state.installer_id_checked_electricity = false),
        (state.company_data_checked_gas_water = false),
        (state.responsible_specialists_checked_gas_water = false),
        (state.documents_checked_gas_water = false),
        (state.professional_competence_checked_gas_water = false),
        (state.installer_id_checked_gas_water = false);
    },
  },
  getters: {
    getCompanyDataElectricitySelected: (state) => {
      return state.company_data_checked_electricity;
    },
    getInstallerIDElectricitySelected: (state) => {
      return state.installer_id_checked_electricity;
    },
    getResponsibleSpecialistsElectricitySelected: (state) => {
      return state.responsible_specialists_checked_electricity;
    },
    getDocumentsElectricitySelected: (state) => {
      return state.documents_checked_electricity;
    },
    getProfessionalCompetenceElectricitySelected: (state) => {
      return state.professional_competence_checked_electricity;
    },
    getExpiryDateElectricitySelected: (state) => {
      return state.expiry_date_electricity_selected;
    },
    getExpiryDateGasValue: (state) => {
      return state.expiry_date_gas;
    },
    getExpiryDateGasSelected: (state) => {
      return state.expiry_date_gas_selected;
    },
    getExpiryDateWaterValue: (state) => {
      return state.expiry_date_water;
    },
    getExpiryDateWaterSelected: (state) => {
      return state.expiry_date_water_selected;
    },
    getCompanyDataGasWaterSelected: (state) => {
      return state.company_data_checked_gas_water;
    },
    getInstallerIDGasWaterSelected: (state) => {
      return state.installer_id_checked_gas_water;
    },
    getInstallerInspectionData: (state) => (internal) => {
      let data = {};
      if (internal) {
        data = {
          company_data_checked_electricity:
            state.company_data_checked_electricity,
          responsible_specialists_checked_electricity:
            state.responsible_specialists_checked_electricity,
          documents_checked_electricity: state.documents_checked_electricity,
          professional_competence_checked_electricity:
            state.professional_competence_checked_electricity,
          company_data_checked_gas_water: state.company_data_checked_gas_water,
          responsible_specialists_checked_gas_water:
            state.responsible_specialists_checked_gas_water,
          documents_checked_gas_water: state.documents_checked_gas_water,
          professional_competence_checked_gas_water:
            state.professional_competence_checked_gas_water,
        };
      } else {
        data = {
          company_data_checked_electricity:
            state.company_data_checked_electricity,
          responsible_specialists_checked_electricity:
            state.responsible_specialists_checked_electricity,
          installer_id_checked_electricity:
            state.installer_id_checked_electricity,
          company_data_checked_gas_water: state.company_data_checked_gas_water,
          responsible_specialists_checked_gas_water:
            state.responsible_specialists_checked_gas_water,
          installer_id_checked_gas_water: state.installer_id_checked_gas_water,
          expiry_date_electricity: state.expiry_date_electricity,
          expiry_date_water: state.expiry_date_water,
          expiry_date_gas: state.expiry_date_gas,
        };
      }
      return data;
    },
    getResponsibleSpecialistsGasWaterSelected: (state) => {
      return state.responsible_specialists_checked_gas_water;
    },
    getDocumentsGasWaterSelected: (state) => {
      return state.documents_checked_gas_water;
    },
    getProfessionalCompetenceGasWaterSelected: (state) => {
      return state.professional_competence_checked_gas_water;
    },
    getIfAllDataElectricitySelected: (state) => (internal) => {
      let valueSelected = {};
      if (internal) {
        valueSelected =
          state.company_data_checked_electricity &&
          state.responsible_specialists_checked_electricity &&
          state.documents_checked_electricity &&
          state.professional_competence_checked_electricity;
      } else {
        valueSelected =
          state.company_data_checked_electricity &&
          state.responsible_specialists_checked_electricity &&
          state.installer_id_checked_electricity &&
          state.expiry_date_electricity_selected;
      }
      return valueSelected;
    },
    getIfAllDataGasWaterSelected: (state) => (internal) => {
      let valueSelected = {};
      if (internal) {
        valueSelected =
          state.company_data_checked_gas_water &&
          state.responsible_specialists_checked_gas_water &&
          state.documents_checked_gas_water &&
          state.professional_competence_checked_gas_water;
      } else {
        valueSelected =
          state.company_data_checked_gas_water &&
          state.responsible_specialists_checked_gas_water &&
          state.installer_id_checked_gas_water;
      }
      return valueSelected;
    },
  },
};

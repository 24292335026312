<template>
  <div class="foo-bar">
    <v-row no-gutters>
      <div class="title-col">
        {{ title }}
      </div>
      <div class="body-col">
        <v-row
          no-gutters
          class="ml-0"
          :class="
            file.type !==
            ('gas_technical_certificate_5' || 'gas_water_file_name')
              ? 'row-margin-bottom'
              : ''
          "
          v-for="(file, index) in filesArray"
          :key="file.type"
        >
          <div
            class="content-col"
            :style="
              index === 0
                ? 'padding-top: 12px; padding-bottom: 2px'
                : index === filesArray.length - 1
                ? 'padding-top: 2px;padding-bottom: 5px'
                : ''
            "
          >
            <div>
              {{ $t(`file_types.${file.type}`) }}
              <v-btn
                v-if="file.file_name"
                icon
                style="min-height: 36px; min-width: 36px; margin-right: 5px"
                @click="downloadInstallerFile(file.file_name)"
              >
                <v-icon v>mdi-download</v-icon>
              </v-btn>
            </div>
            <slot v-if="index === filesArray.length - 1"></slot>
          </div>
          <div
            class="button-col"
            :style="
              first && index === 0
                ? 'border-radius: 0 8px 0 0'
                : last && index === filesArray.length - 1
                ? 'border-radius: 0 0 8px 0'
                : ''
            "
          >
            <v-btn icon @click="$emit('edit', file)">
              <edit-icon />
            </v-btn>
          </div>
        </v-row>
      </div>
    </v-row>
  </div>
</template>

<script>
import EditIcon from '../../icons/EditIcon';
import fileMethods from '../../helpers/fileMethods';

export default {
  name: 'InstallerFileRow',
  components: {
    EditIcon,
  },
  props: {
    title: {
      type: String,
      default: '-',
    },
    first: {
      type: Boolean,
      default: false,
    },
    last: {
      type: Boolean,
      default: false,
    },
    filesArray: {
      type: Array,
    },
  },
  methods: {
    downloadInstallerFile(item) {
      fileMethods.downloadFile(item, 'Installer');
    },
  },
};
</script>

<style scoped>
.title-col {
  padding: 18px 0 0 25px;
  width: 29.5%;
  font-size: 16px;
  letter-spacing: 0;
  color: #6e6e6e;
  background-color: rgba(255, 255, 255, 0.9);
  opacity: 1;
  line-height: 1;
}

.body-col {
  width: 70.5%;
}
.content-col {
  background-color: rgba(255, 255, 255, 0.9);
  width: 92%;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0;
  color: #6e6e6e;
  opacity: 1;
  border-right: 1px solid #999999;
  align-items: center;
  display: flex;
}
.button-col {
  width: 8%;
  min-width: 48px;
  justify-content: center;
  align-items: center;
  display: flex;
  filter: brightness(1.1);
  background-color: rgba(255, 255, 255, 0.6);
}
.row-margin-bottom {
  margin-bottom: 1px !important;
}

.last-row-of-section .body-col .row-margin-bottom:last-child {
  margin-bottom: 0px !important;
}

.first-row {
  margin-top: 1px;
}

.last-row {
  margin-bottom: 0px;
}

.last-row .row-margin-bottom {
  margin-bottom: 0px !important;
  padding-bottom: 0px;
}

.last-row .content-col {
  padding-bottom: 18px !important;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('RowItem',{staticClass:"row-item",attrs:{"first":"","field":"updatePVSystemInfos","title":_vm.feedInTitle,"editable":!_vm.hasStatusOrderTransmitted},on:{"edit":function($event){return _vm.$emit('edit-component', {
        component: 'PhotovoltaicSystemComponent',
        location: 'technicalDetailsComponent',
        properties: _vm.feedInSystem,
      })}}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.presentableFeedInSystem.pvSystemInfo)}})]),_c('RowItem',{staticClass:"row-item",attrs:{"title":_vm.$t('feed_in_system_details.pv_system_key'),"editable":false}},[_vm._v(" "+_vm._s(_vm.feedInSystem.pv_system_key ? _vm.feedInSystem.pv_system_key : '-')+" ")]),(_vm.feedInSystem.pv_inverters)?_c('RowItem',{staticClass:"row-item",attrs:{"field":"updatePVInverter","title":_vm.$t('feed_in_system_details.inverter_performance'),"editable":!_vm.hasStatusOrderTransmitted},on:{"edit":function($event){return _vm.$emit('edit-component', {
        component: 'InverterModelComponent',
        location: 'technicalDetailsComponent',
        properties: _vm.feedInSystem,
      })}}},_vm._l((_vm.feedInSystem.pv_inverters),function(item,index){return _c('div',{key:index,staticClass:"row-item__inverter"},[_c('div',{staticClass:"list-item"},[_c('span',[_vm._v(_vm._s(_vm.$t('feed_in_system_details.inverter') + ' ' + (index + 1)))]),(!item.id)?_c('v-tooltip',{attrs:{"left":"","color":"primary","max-width":"150px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c(_vm.IconAusrufungszeichen,{tag:"component"})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('feed_in_system_details.inverter_not_from_list')))])]):_vm._e()],1),_c('div',{staticClass:"list-item"},[_c('span',[_vm._v(_vm._s(_vm.$t('inverter_model_step.manufacturer'))+": "+_vm._s(item.manufacturer))])]),_c('div',{staticClass:"list-item"},[_c('span',[_vm._v(_vm._s(_vm.$t('inverter_model_step.type'))+": "+_vm._s(item.type))])]),_c('div',{staticClass:"list-item"},[_c('span',[_vm._v(_vm._s(_vm.$t('feed_in_system_details.amount'))+": "+_vm._s(item.number_of_inverters))])]),_c('div',{staticClass:"list-item"},[_vm._v(" "+_vm._s(_vm.$t('feed_in_system_details.active_power'))+": "+_vm._s(_vm.divideByThousand(item.active_power))+" "+_vm._s(_vm.$t('feed_in_system_details.kW'))+" ")]),_c('div',{staticClass:"list-item"},[_vm._v(" "+_vm._s(_vm.$t('feed_in_system_details.apparent_power'))+": "+_vm._s(_vm.divideByThousand(item.apparent_power))+" "+_vm._s(_vm.$t('feed_in_system_details.kVA'))+" ")]),_c('div',{staticClass:"list-item"},[_vm._v(" "+_vm._s(_vm.$t('feed_in_system_details.rated_current'))+": "+_vm._s(item.rated_current)+" "+_vm._s(_vm.$t('feed_in_system_details.A'))+" ")]),_c('div',{staticClass:"list-item"},[_vm._v(" "+_vm._s(_vm.$t('feed_in_system_details.e4_cert_number'))+": "+_vm._s(item.e4_cert_number)+" ")]),_c('div',{staticClass:"list-item"},[_vm._v(" "+_vm._s(_vm.$t('feed_in_system_details.e6_cert_number'))+": "+_vm._s(item.e6_cert_number)+" ")])])}),0):_vm._e(),_c('RowItem',{staticClass:"row-item",attrs:{"field":"updatePVExistingSystems","title":_vm.$t('feed_in_system_details.existing_systems'),"editable":!_vm.hasStatusOrderTransmitted},on:{"edit":function($event){return _vm.$emit('edit-component', {
        component: 'ExistingSystemsComponent',
        location: 'technicalDetailsComponent',
        properties: _vm.feedInSystem,
      })}}},[(_vm.hasExistingSystems)?_c('div',_vm._l((_vm.presentableFeedInSystem.existingSystems),function(system){return _c('div',{key:system.name,staticClass:"list-item",staticStyle:{"flex-wrap":"wrap"}},[_c('span',[_vm._v(_vm._s(system.name))]),_c('span',[_vm._v(" "+_vm._s(_vm.numberToGermanFormat(system.power))+" "+_vm._s(system.unit)+" ")]),_c('span',{staticStyle:{"flex-basis":"100%","height":"0"}}),_c('span',[_vm._v(_vm._s(system.date_label)+" ")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(system.date))])])}),0):_vm._e(),(!_vm.hasExistingSystems)?_c('div',[_vm._v(" "+_vm._s(_vm.$t('feed_in_system_details.no_existing_system'))+" ")]):_vm._e()]),_c('RowItem',{staticClass:"row-item",attrs:{"field":"updatePVLocation","title":_vm.$t('feed_in_system_details.location_of_new_system'),"editable":!_vm.hasStatusOrderTransmitted},on:{"edit":function($event){return _vm.$emit('edit-component', {
        component: 'SystemLocationComponent',
        location: 'technicalDetailsComponent',
        properties: _vm.feedInSystem,
      })}}},[_c('div',[_vm._v(_vm._s(_vm.pvLocation))]),(_vm.coordinates)?_c('div',[_vm._v(" "+_vm._s(_vm.$t('feed_in_system_details.coordinates'))+": "+_vm._s(_vm.coordinates.lat)+", "+_vm._s(_vm.coordinates.lng)+" ")]):_vm._e()]),_c('RowItem',{staticClass:"row-item",attrs:{"field":"updatePVEnergyStorage","title":_vm.$t('feed_in_system_details.storage'),"editable":!_vm.hasStatusOrderTransmitted},on:{"edit":function($event){return _vm.$emit('edit-component', {
        component: 'StorageComponent',
        location: 'technicalDetailsComponent',
        properties: _vm.feedInSystem,
      })}}},[_c('div',{staticClass:"energy-storag-content"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.presentableFeedInSystem.energyStorage)}}),_c('div',{staticClass:"storage-tooltip"},[(
            _vm.feedInSystem.energy_storage && !_vm.feedInSystem.energy_storage.id
          )?_c('v-tooltip',{attrs:{"left":"","color":"primary","max-width":"150px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c(_vm.IconAusrufungszeichen,{tag:"component"})],1)]}}],null,false,74136142)},[_c('span',[_vm._v(_vm._s(_vm.$t('feed_in_system_details.storage_not_from_list')))])]):_vm._e()],1)])]),(_vm.feedInSystem.is_storage_planned && _vm.hasProtokollStatus)?_c('RowItem',{staticClass:"row-item",attrs:{"field":"updatePVStorageMaStRData","title":_vm.$t('feed_in_system_details.storage_market_data_register'),"editable":!_vm.hasStatusOrderTransmitted},on:{"edit":function($event){return _vm.$emit('edit-component', {
        component: 'StorageMarketDataRegisterComponent',
        location: 'technicalDetailsComponent',
      })}}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.presentableFeedInSystem.storageMaStR)}})]):_vm._e(),_c('RowItem',{staticClass:"row-item",attrs:{"title":_vm.$t('feed_in_system_details.pv_storage_key'),"editable":false}},[_vm._v(" "+_vm._s(_vm.feedInSystem.pv_storage_key ? _vm.feedInSystem.pv_storage_key : '-')+" ")]),_c('RowItem',{staticClass:"row-item",attrs:{"field":"updatePVFileNames","title":_vm.$t('feed_in_system_details.plans'),"editable":!_vm.hasStatusOrderTransmitted},on:{"edit":function($event){return _vm.$emit('edit-component', {
        component: 'PVFileUploadComponent',
        location: 'PVFileUploadComponent',
        properties: _vm.feedInSystem,
      })}}},_vm._l((_vm.plans),function(plan,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(plan)+" ")])}),0),(_vm.presentableFeedInSystem.measurementConcept && !_vm.isPVAnfrage)?_c('RowItem',{staticClass:"row-item",attrs:{"field":"updatePVMeasurementConcept","title":_vm.$t('feed_in_system_details.measurement_concept'),"editable":!_vm.hasStatusOrderTransmitted},on:{"edit":function($event){return _vm.$emit('edit-component', {
        component: 'MeasureConceptComponent',
        location: 'MeasureConceptComponent',
        properties: _vm.feedInSystem,
      })}}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.presentableFeedInSystem.measurementConcept)}}),_c('br'),_c('div',{staticClass:"margin-top-minus",domProps:{"innerHTML":_vm._s(_vm.measurementConceptSmallTitle)}})]):_vm._e(),(!_vm.isPVAnfrage)?_c('RowItem',{staticClass:"row-item",attrs:{"field":"updatePVFeedInProspect","title":_vm.$t('feed_in_system_details.forecast'),"editable":!_vm.hasStatusOrderTransmitted},on:{"edit":function($event){return _vm.$emit('edit-component', {
        component: 'FeedInProspectComponent',
        location: 'FeedInProspectComponent',
      })}}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.presentableFeedInSystem.feedInProspect)}})]):_vm._e(),(_vm.feedInSystem.feed_in_profile)?_c('RowItem',{staticClass:"row-item",attrs:{"title":_vm.$t('feed_in_system_details.feed_in_profile'),"editable":false}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.feedInSystem.feed_in_profile)}})]):_vm._e(),(
      _vm.feedInSystem.status === _vm.feedInStatuses.fulfilled_conf.value ||
      _vm.feedInSystem.status === _vm.feedInStatuses.transmitted.value
    )?_c('RowItem',{staticClass:"row-item",attrs:{"field":"updatePVFeedInManagement","title":'Einspeisemanagement',"editable":!_vm.hasStatusOrderTransmitted},on:{"edit":function($event){return _vm.$emit('edit-component', {
        component: 'FeedInManagementComponent',
        location: 'FeedInManagementComponent',
      })}}},[_c('div',[_vm._v(" "+_vm._s(_vm.feedInSystem.feedin_management ? _vm.$t( ("feedin_management." + (_vm.feedInSystem.feedin_management.toString())) ) : '-')+" ")]),_c('div',[_c('span',[_vm._v("Datum ab:")]),_vm._v("   "),_c('span',[_vm._v(_vm._s(_vm.formatDate(_vm.feedInSystem.implementing_date)))])])]):_vm._e(),(_vm.hasProtokollStatus)?_c('RowItem',{staticClass:"row-item",attrs:{"title":_vm.$t('feed_in_system_details.power_flow_monitoring'),"editable":!_vm.hasStatusOrderTransmitted},on:{"edit":function($event){return _vm.$emit('edit-component', {
        component: 'PowerFlowMonitoringComponent',
        location: 'technicalDetailsComponent',
        properties: _vm.feedInSystem,
      })}}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.presentableFeedInSystem.powerFlowMonitoring)}})]):_vm._e(),(_vm.hasProtokollStatus)?_c('RowItem',{staticClass:"row-item",attrs:{"field":"updatePhotovoltaics","title":_vm.$t('feed_in_system_details.system_protection'),"editable":!_vm.hasStatusOrderTransmitted},on:{"edit":function($event){return _vm.$emit('edit-component', {
        component: 'SystemProtectionComponent',
        location: 'SystemProtectionComponent',
        properties: {
          feedInSystemSystemProtection: _vm.feedInSystemSystemProtection,
        },
      })}}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.presentableFeedInSystem.systemProtection)}})]):_vm._e(),(_vm.hasProtokollStatus)?_c('RowItem',{staticClass:"row-item",attrs:{"title":_vm.$t('feed_in_system_details.symetry_requirement'),"editable":!_vm.hasStatusOrderTransmitted},on:{"edit":function($event){return _vm.$emit('edit-component', {
        component: 'SymmetryRequirementComponent',
        location: 'technicalDetailsComponent',
        properties: _vm.feedInSystem,
      })}}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.presentableFeedInSystem.symmetryRequirement)}})]):_vm._e(),(_vm.hasProtokollStatus)?_c('RowItem',{staticClass:"row-item",attrs:{"last":!_vm.feedInSystem.permissions.perm_of_cost_radio_ripple_control_receiver &&
      !_vm.feedInSystem.permissions.perm_of_cost_remote_control_system,"title":_vm.$t('feed_in_system_details.reactive_power_provision'),"editable":!_vm.hasStatusOrderTransmitted},on:{"edit":function($event){return _vm.$emit('edit-component', {
        component: 'ReactivePowerProvisionComponent',
        location: 'ReactivePowerProvisionComponent',
        enableNote: true,
      })}}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.presentableFeedInSystem.reactivePowerProvision)}})]):_vm._e(),(
      _vm.feedInSystem.permissions.perm_of_cost_radio_ripple_control_receiver ||
      _vm.feedInSystem.permissions.perm_of_cost_remote_control_system
    )?_c('RowItem',{staticClass:"row-item",attrs:{"last":"","title":_vm.$t('feed_in_system_details.control_device'),"editable":!_vm.hasStatusOrderTransmitted},on:{"edit":function($event){return _vm.$emit('edit-component', {
        component: 'ParameterSetComponent',
        location: 'ParameterSetComponent',
        enableNote: false,
      })}}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.controlDeviceValue)}}),_c('span',{staticClass:"param-title"},[_vm._v(_vm._s(_vm.$t('feed_in_system_details.parameter_set')))]),_c('span',{staticClass:"param-content",domProps:{"innerHTML":_vm._s(_vm.parameterSet)}})]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
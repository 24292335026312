var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.feedInSystem.reactive_power_type)?_c('RowItem',{staticClass:"row-item",attrs:{"first":"","title":_vm.$t('feed_in_system_details.reactive_power_type'),"editable":!_vm.hasStatusOrderTransmitted},on:{"edit":function($event){return _vm.$emit('edit-component', {
        component: 'ReactivePowerSelectorComponent',
        location: 'MeasurementData',
        properties: _vm.feedInSystem,
      })}}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.feedInSystem.reactive_power_type)}})]):_vm._e(),_c('RowItem',{staticClass:"row-item",attrs:{"first":!_vm.feedInSystem.reactive_power_type,"field":"updatePVVoltageLevel","title":_vm.$t('feed_in_system_details.voltage_level'),"editable":_vm.canEditCustomerStationAndVoltage && !_vm.hasStatusOrderTransmitted},on:{"edit":function($event){return _vm.$emit('edit-component', {
        component: 'VoltageLevelComponent',
        location: 'VoltageLevel',
        properties: _vm.feedInSystem,
      })}}},[_c('div',[_vm._v(_vm._s(_vm.translateVoltageLevel))])]),_c('RowItem',{staticClass:"row-item",attrs:{"field":"updatePVCustomerStation","title":_vm.$t('feed_in_system_details.customer_station'),"editable":_vm.canEditCustomerStationAndVoltage && !_vm.hasStatusOrderTransmitted},on:{"edit":function($event){return _vm.$emit('edit-component', {
        component: 'CustomerStationComponent',
        location: 'CustomerStation',
        properties: _vm.feedInSystem,
      })}}},[_c('div',[_vm._v(_vm._s(_vm.translateCustomerStation))])]),_c('RowItem',{staticClass:"row-item",attrs:{"field":"updatePVMeasurementLocation","title":("<strong>" + (_vm.$t(
      'feed_in_system_details.measurement_location'
    )) + "</strong> <br> " + (_vm.$t(
      'feed_in_system_details.measurement_location_fields'
    ))),"editable":(_vm.feedInSystem.status === _vm.feedInStatuses.in_impl_conf.value ||
        _vm.feedInSystem.status === _vm.feedInStatuses.fulfilled.value ||
        _vm.feedInSystem.status === _vm.feedInStatuses.fulfilled_insp.value ||
        _vm.feedInSystem.status === _vm.feedInStatuses.fulfilled_conf.value) &&
      !_vm.hasStatusOrderTransmitted},on:{"edit":function($event){return _vm.$emit('edit-component', {
        component: 'MeasurementLocationComponent',
        location: 'MeasurementData',
        properties: _vm.feedInSystem,
      })}}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.presentableFeedInSystem.measurementLocation)}})]),_c('RowItem',{staticClass:"row-item",attrs:{"last":"","field":"updatePVMarketLocation","title":("<strong>" + (_vm.$t(
      'feed_in_system_details.market_location'
    )) + "</strong><br>" + (_vm.$t('feed_in_system_details.market_location_fields'))),"editable":_vm.feedInSystem.status === _vm.feedInStatuses.fulfilled_conf.value &&
      !_vm.hasStatusOrderTransmitted},on:{"edit":function($event){return _vm.$emit('edit-component', {
        component: 'MarketLocationComponent',
        location: 'MeasurementData',
        properties: _vm.feedInSystem,
      })}}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.presentableFeedInSystem.marketLocation)}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
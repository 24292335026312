import router from '../../router';
import * as dayjs from 'dayjs';
import * as weekOfYear from 'dayjs/plugin/weekOfYear';
import * as localizedFormat from 'dayjs/plugin/localizedFormat';
dayjs.extend(weekOfYear);
dayjs.extend(localizedFormat);
import {
  addressDataObject,
  invoiceAddressObject,
  installationAddressObject,
  wallboxLocationObject,
  emobilityInstallationObject,
  mapProductName,
  dateWithUnitObject,
  additionalContactPersonObject,
  installationCompanyObject,
  feedInProspectObject,
  maStRObject,
  pvSystemChangedObject,
  pvInverterObject,
  MeasureConceptObject,
  feedInCompensationObject,
  energyStorageObject,
  pvContactDataObject,
  insertSpacesToIBAN,
  presentableDayDate,
  counterDataHistoryObject,
  tenantElectricitySurchargeObject,
  pvFeedinManagementObject,
  alternateInvoiceRecipientDataObject,
  alternateDeliveryAddressDataObject,
  showSystemProtectionInHistoryTable,
} from '@/helpers/transformToPresentableData';
import i18n from '../../plugins/i18n';
import store from '../../store';
import { removeUUIDFromFileName } from '@/helpers/globalFunctions';
import {
  type_of_usage_options,
  CURRENT_DIRECTION_LIST,
} from '../feed-in-system-details/FeedInTechnicalInspectionConstants';

const newLine = '<br>';

export function transformRawDataToHistoryTableFormat(
  rawData,
  product,
  eventName
) {
  if (eventName === 'resendInstallerECReminder') {
    return resendInstallerECReminderObject(rawData);
  } else if (eventName === 'resendPVMStRDReminder') {
    return resendPVMStRDReminderObject(rawData);
  } else if (eventName === 'resendPVStorageMStRDReminder') {
    return resendPVStorageMStRDReminderObject(rawData);
  } else if (eventName === 'resendPVFDReminder') {
    return resendPVFDReminderObject(rawData);
  } else if (eventName === 'resendPVIReminder') {
    return resendPVIReminderObject(rawData);
  } else if (eventName === 'resendPVICEIReminder') {
    return resendPVICEIReminderObject(rawData);
  } else if (eventName === 'renewInstallerConcession') {
    return concessionObject(rawData);
  } else if (
    'expiry_date_gas' in rawData ||
    'expiry_date_water' in rawData ||
    'expiry_date_electricity' in rawData
  ) {
    const formattedDates = [];
    if (rawData.expiry_date_gas) {
      formattedDates.push(
        `Ablaufdatum Gas: ${formatDate(rawData.expiry_date_gas)}`
      );
    }
    if (rawData.expiry_date_water) {
      formattedDates.push(
        `Ablaufdatum Trinkwasser: ${formatDate(rawData.expiry_date_water)}`
      );
    }
    if (rawData.expiry_date_electricity) {
      formattedDates.push(
        `Ablaufdatum Strom: ${formatDate(rawData.expiry_date_electricity)}`
      );
    }
    return formattedDates.length > 0 ? formattedDates.join('<br>') : '-';
  } else if ('derived_from_order' in rawData) {
    return (
      'Bestandteil eines <a href="' +
      router.resolve({ name: 'Details', params: { id: rawData.id } }).href +
      '">beauftragten Netzanschlusses</a>'
    );
  } else if (eventName === 'updatePVDemKeys') {
    return inverterPowerObject(rawData);
  } else if (
    rawData.product_installation_full_address ||
    rawData.product_installation_with_plot_number_address
  ) {
    return installationAddressObject(rawData);
  } else if ('partner' in rawData) {
    return null;
  } else if ('sap' in rawData) {
    return sapDataObject(rawData);
  } else if ('error' in rawData) {
    return errorObject(rawData);
  } else if ('product' in rawData) {
    return productObject(rawData);
  } else if (
    'gas_installations' in rawData ||
    'electric_installations' in rawData ||
    'water_installations' in rawData
  ) {
    return installationsObject(rawData);
  } else if ('own_contribution_selected' in rawData) {
    return priceObject(rawData, product);
  } else if (
    'uploaded_file_names_blueprint' in rawData ||
    'uploaded_file_names_cadastral' in rawData
  ) {
    return fileObject(rawData);
  } else if (
    'appointment_for_connection' in rawData &&
    !('montage' in rawData) &&
    store.getters.getSelectedPhotovoltaic.journey !== 'photovoltaik'
  ) {
    return dateWithUnitObject(rawData);
  } else if ('implementing_date' in rawData) {
    return presentableDayDate(rawData.implementing_date);
  } else if (
    'installation_company_gas' in rawData ||
    'installation_company_electricity' in rawData
  ) {
    return installationCompanyObject(rawData);
  } else if (
    'applicant' in rawData ||
    'plant_operator' in rawData ||
    'landowner' in rawData
  ) {
    return pvContactDataObject(rawData);
  } else if ('alternative_invoice' in rawData) {
    return rawData.alternative_invoice ? invoiceAddressObject(rawData) : '-';
  } else if ('additional_contact_person' in rawData) {
    return additionalContactPersonObject(rawData);
  } else if ('montage' in rawData) {
    return constructionWorkObject(rawData);
  } else if ('customer_information_checked' in rawData) {
    return technicalInspectionObject(rawData);
  } else if ('company_data' in rawData) {
    return companyDataObject(rawData.company_data);
  } else if ('responsible_specialists' in rawData) {
    return responsibleSpecialistsObject(rawData);
  } else if ('contact_person' in rawData) {
    return contactPersonObject(rawData.contact_person);
  } else if ('file_names' in rawData) {
    return installerFileObject(rawData.file_names);
  } else if ('file_uploads' in rawData) {
    return orderEmobilityFileObject(rawData);
  } else if ('status' in rawData) {
    return installerStatusObject(rawData);
  } else if ('expiry' in rawData) {
    return installerExpiryDateObject(rawData);
  } else if ('wallbox_location' in rawData) {
    return wallboxLocationObject(rawData);
  } else if ('e_mobility_installation' in rawData) {
    return emobilityInstallationObject(rawData);
  } else if (
    ('counter_id_data' in rawData || 'new_counter_data' in rawData) &&
    !('measure_concept' in rawData)
  ) {
    return counterDataHistoryObject(rawData);
  } else if ('maStR' in rawData) {
    return maStRObject(rawData.maStR);
  } else if ('storage_maStR' in rawData) {
    return maStRObject(rawData.storage_maStR);
  } else if ('system_protection' in rawData) {
    return showSystemProtectionInHistoryTable(rawData.system_protection);
  } else if ('pv_system_infos' in rawData) {
    return pvSystemChangedObject(rawData);
  } else if ('pv_inverters' in rawData && rawData.pv_inverters) {
    return pvInverterObject(rawData.pv_inverters);
  } else if ('concession_division' in rawData) {
    return concessionDivisionObject(rawData.concession_division);
  } else if ('company_data_checked_electricity' in rawData) {
    return installerInspectionObject(rawData, product);
  } else if (
    'assembly_gas_water_company' in rawData ||
    'assembly_electricity_company' in rawData ||
    'groundwork_company' in rawData ||
    'internal_construction_management' in rawData
  ) {
    return constructionWorkNEWObject(rawData);
  } else if ('note_file' in rawData) {
    return `Hochgeladene Datei: ${rawData.note_file}`;
  } else if ('existing_systems' in rawData) {
    return existingSystemsObject(rawData);
  } else if ('measure_concept' in rawData) {
    return (
      MeasureConceptObject(rawData) +
      ('tenant_electricity_surcharge' in rawData
        ? '<br>' +
          tenantElectricitySurchargeObject(rawData.tenant_electricity_surcharge)
        : '') +
      ('counter_id_data' in rawData
        ? '<br>' + counterDataHistoryObject(rawData)
        : '')
    );
  } else if ('feed_in_compensation' in rawData) {
    return feedInCompensationObject(rawData);
  } else if ('feed_in_prospect_wh' in rawData) {
    return feedInProspectObject(rawData);
  } else if ('pv_location' in rawData) {
    return pvLocationObject(rawData);
  } else if ('alternate_invoice_recipient' in rawData) {
    return alternateInvoiceRecipientDataObject(rawData);
  } else if ('alternate_delivery_address' in rawData) {
    return alternateDeliveryAddressDataObject(rawData);
  } else if ('energy_storage' in rawData) {
    return energyStorageObject(rawData);
  } else if ('finance_data' in rawData) {
    return financeDataObject(rawData);
  } else if (eventName === 'updatePVMarketLocation') {
    return marketLocationObject(rawData);
  } else if (eventName === 'updatePVMeasurementLocation') {
    return measurementLocationObject(rawData);
  } else if (eventName === 'updatePVCustomerStation') {
    return customerStationObject(rawData);
  } else if (eventName === 'updateVoltageLevel') {
    return voltageLevelObject(rawData);
  } else if ('pvimplementation_inspection' in rawData) {
    return pvImplementationInspectionObject(rawData);
  } else if ('pvrequest_inspection' in rawData) {
    return pvRequestInspectionObject(rawData);
  } else if ('pvimplementationprotocol_inspection' in rawData) {
    return pvImplementationProtokollInspectionObject(rawData, eventName);
  } else if ('feedin_management' in rawData) {
    return pvFeedinManagementObject(rawData.feedin_management);
  } else if ('parameter_set' in rawData) {
    return parameterSetObject(rawData);
  } else if ('reactive_power_provision' in rawData) {
    return reactivePowerProvisionObject(rawData);
  } else if ('reactive_power_type' in rawData) {
    return reactivePowerTypeObject(rawData);
  } else if ('power_flow_monitoring' in rawData) {
    return powerFlowMonitoringObject(rawData);
  } else if ('symmetry_requirement' in rawData) {
    return symmetryRequirementObject(rawData);
  } else {
    if (Object.keys(rawData).length === 0) {
      return '-';
    } else {
      return rawData;
    }
  }
}

function buildResendPVIReminderText(payload) {
  const reminderType = payload?.new_value?.implementation_pending_reminder_type;
  if (!reminderType)
    return 'Nachtragsmail zu Inbetriebnahme in Prüfung an Kunden erzeugt';

  let outputText = 'Erinnerungsmail zur';

  switch (reminderType) {
    case '2months':
      outputText +=
        ' auslaufenden Einspeisezusage in vier Monaten an Kunden erzeugt';
      break;
    case '4months':
      outputText +=
        ' auslaufenden Einspeisezusage in zwei Monaten an Kunden erzeugt';
      break;
    case 'last_before_deprecation':
      outputText +=
        ' auslaufenden Einspeisezusage in einer Woche an Kunden erzeugt';
      break;
    case 'deprecated':
      outputText +=
        ' bevorstehenden Stornierung der Einspeisezusage an Kunden erzeugt';
      break;
  }
  return outputText;
}

export function mapEventNameToTitle(change, title) {
  const payload = JSON.parse(change.payload);
  switch (change.field) {
    case 'resendInstallerECReminder':
      return 'Erinnerungsmail zum abgelaufenen Installateurausweis';
    case 'resendPVStorageMStRDReminder':
      return 'Nachtragsmail zu Speicher MaStR an Kunden erzeugt';
    case 'resendPVMStRDReminder':
      return 'Nachtragsmail zu MaStR PV an Kunden erzeugt';
    case 'resendPVFDReminder':
      return 'Nachtragsmail zu Finanzwirtschaftlichen Daten an Kunden erzeugt';
    case 'resendPVIReminder':
      return buildResendPVIReminderText(payload);
    case 'resendPVICEIReminder':
      return 'Nachtragsmail zu Elektrofachbetrieb an Kunden erzeugt';
    case 'updatePVParameterSet':
      return 'Parametersatz geändert';
    case 'updateInstallerConcessionDivision':
      return 'Installateurausweissparte geändert';
    case 'renewInstallerConcession':
      return 'Installateurausweisverlängerung';
    case 'updateFilesName':
      return 'Baupläne geändert';
    case 'updateInstallerFilesName':
      return 'Fachliche Nachweise geändert';
    case 'updateInstallerCompanyData':
      return 'Unternehmensdaten geändert';
    case 'updateInstallerContactPerson':
      return 'Ansprechpartner geändert';
    case 'updateInstallerPeople':
      return 'Verantwortliche Fachkraft geändert';
    case 'deliverInstallerBadge':
      return 'Installateurausweis versendet';
    case 'updateInstallerConcessionExpiry':
      return 'Laufzeit des Installateurausweises geändert';
    case 'updateInstallerConcessionStatus':
    case 'updatePVStatus':
      return 'Status geändert';
    case 'updateOrderEmobilityChargingPower':
      return 'Ladeleistung geändert';
    case 'updateOrderEmobilityWallboxLocation':
      return 'Ladeort geändert';
    case 'updateOrderEmobilityFilesName':
      return 'Ladeplan geändert';
    case 'updateInstallerInspectionData':
      return payload.status
        ? 'Prüfung abgeschlossen'
        : 'Zwischenstand gespeichert';
    case 'extendInstallerConcession':
      return 'Installateurausweis in Netzportal übernommen';
    case 'createOrderRequest':
    case 'createPVRequest':
      return 'Anfrage angelegt';
    case 'updatePVAlternateInvoiceRecipient':
      return 'Abweichender Rechnungsempfänger geändert';
    case 'updatePVAlternateDeliveryAddress':
      return 'Abweichende Lieferadresse geändert';
    case 'updatePVRequestInspectionData':
    case 'updatePVImplementationInspectionData':
    case 'updatePVImplementationProtocolInspectionData':
      return payload.status === 'completed'
        ? 'Technische Prüfung abgeschlossen'
        : 'Zwischenstand gespeichert';
    case 'createPVImplementationRequest':
      return 'Inbetriebnahmeantrag angelegt';
    case 'createPVImplementationProtocolRequest':
      return 'Inbetriebnahmeprotokoll angelegt';
    case 'createEMobilityRequest':
    case 'requestInstallerConcession':
      return 'Auftrag angelegt';
    case 'updateLandowner':
      return 'Grundstückseigentümer geändert';
    case 'updateProduct':
      return 'Anschlussart geändert';
    case 'updateConstructionData':
      return 'Bauausführungsdaten geändert';
    case 'updateInstallationCompany':
      return 'Installationsunternehmen geändert';
    case 'updateAdditionalContactPerson':
      return 'Weitere Ansprechpartner geändert';
    case 'updateInvoiceAddress':
      return 'Rechnungsanschrift geändert';
    case 'updateApplicant':
      return 'Auftraggeber geändert';
    case 'updateAppointmentForConnection':
      return 'Wunschtermin geändert';
    case 'updatePVImplementationAppointment':
      return 'Inbetriebnahmetermin geändert';
    case 'updatePricingsData':
      return 'Netzanschlusskosten geändert';
    case 'updateInstallations':
      return 'Installationen geändert';
    case 'updateInstallationAddress':
      return 'Anschlussadresse geändert';
    case 'updateTechnicalInspectionData':
      return payload.status === 'completed'
        ? 'Technische Prüfung abgeschlossen'
        : 'Zwischenstand gespeichert';
    case 'updateOrderSAPKeys':
    case 'notifyOrderSAPKeys':
      return 'Erfolgreiche Übermittlung an SAP';
    case 'notifyOrderSAPError':
      return 'Übermittlung an SAP gescheitert';
    case 'updateOrderSAPError':
      return 'Übermittlung an SAP gescheitert';
    case 'emailSent':
      // Note: Discontinued feature
      return 'Email versendet';
    case 'updateEmobilityApplicant':
      return 'Auftraggeber geändert';
    case 'updateEmobilityWallboxLocation':
      return 'Standort geändert';
    case 'updateEmobilityInstallationAddress':
      return 'Anschlussadresse geändert';
    case 'updateEmobilityChargingPower':
      return 'Ladeleistung geändert';
    case 'updateEmobilityFilesName':
      return 'Baupläne geändert';
    case 'updateEmobilityAdditionalContactPerson':
      return 'Weiterer Ansprechpartner geändert';
    case 'updateEmobilityLandowner':
      return 'Grundstückseigentümer geändert';
    case 'updateEmobilityInspectionData':
      return payload.status
        ? 'Prüfung abgeschlossen'
        : 'Zwischenstand gespeichert';
    case 'updatePVCounterId':
      return 'Zählernummer geändert';
    case 'updatePVCountersData':
      return 'Zählerdaten geändert';
    case 'updatePVSystemProtection':
      return 'NA-Schutz geändert';
    case 'updatePVMaStRData':
      return 'Marktstammdaten-Registerdaten geändert';
    case 'updatePVStorageMaStRData':
      return 'Marktstammdaten Speicher-Registerdaten geändert';
    case 'updatePVSystemInfos':
      return 'Einspeisedaten geändert';
    case 'updatePVInverter':
      return 'Wechselrichter geändert';
    case 'updatePVExistingSystems':
      return 'Bereits bestehende Erzeugungsanlagen geändert';
    case 'updatePVMeasurementConcepts':
      if (
        'counter_id_data' in payload.old_value &&
        'tenant_electricity_surcharge' in payload.old_value
      ) {
        return 'Messkonzept, Mieterstromzuschlag und Zählernummer geändert';
      } else if ('counter_id_data' in payload.old_value) {
        return 'Messkonzept und Zählernummer geändert';
      } else if ('tenant_electricity_surcharge' in payload.old_value) {
        return 'Messkonzept und Mieterstromzuschlag geändert';
      } else {
        return 'Messkonzept geändert';
      }
    case 'updatePVFeedInCompensation':
      return 'Einspeisevergütung geändert';
    case 'updatePVFeedInProspect':
      return 'Einspeiseprognose geändert';
    case 'updatePVLocation':
      return 'Standort der Anlage geändert';
    case 'updatePVFileNames':
      return 'Pläne geändert';
    case 'updatePVEnergyStorage':
      return 'Speicher geändert';
    case 'updatePVElectricInstallerCompany':
      return 'Elektrofachbetrieb geändert';
    case 'updatePVInstallationAddress':
      return 'Einspeiseadresse geändert';
    case 'updatePVContactData':
      return 'Kontaktdaten geändert';
    case 'updatePVFinanceData':
      return 'Finanzwirtschaftliche Daten geändert';
    case 'updatePVFeedInManagement':
      return 'Einspeisemanagement geändert';
    case 'updatePVPowerFlowMonitoring':
      return 'Leistungsflussüberwachung geändert';
    case 'updatePVReactivePowerProvision':
      return 'Blindleistungsbereitstellung geändert';
    case 'updatePVReactivePowerType':
      return 'Blindleistungsart geändert';
    case 'updatePVSymmetryRequirement':
      return 'Symmetriebedingung geändert';
    case 'updatePVMarketLocation':
      return 'Marktlokation geändert';
    case 'updatePVMeasurementLocation':
      return 'Messlokation geändert';
    case 'updatePVCustomerStation':
      return 'Kundenstation geändert';
    case 'updatePVVoltageLevel':
      return 'Spannungsebene geändert';
    case 'updatePVDemKeys':
      return 'Wechselrichterleistung geändert';
    case 'addInstallerNote':
    case 'addOrderNote':
    case 'addEmobilityNote':
      return title ? title : '';
    case 'resetOrderEmobilityPayload':
      return 'Emobilität-Payload zurückgesetzt';
    case 'deliverPVConfirmation':
      return 'Bestätigung versendet';
    default:
      if (title) {
        return title;
      }
      return camelCaseToReadable(change.field);
  }
}

function parameterSetObject(rawData) {
  return (
    i18n.t('feed_in_system_details.parameter_set') +
    ': ' +
    // eslint-disable-next-line
    (rawData.parameter_set || '-')
  );
}
function reactivePowerProvisionObject(rawData) {
  return (
    i18n.t('reactive_power_provision.title') +
    ': ' +
    i18n.t(`reactive_power_provision.${rawData.reactive_power_provision}`)
  );
}

function reactivePowerTypeObject(rawData) {
  return (
    i18n.t('reactive_power_type.title') +
    ': ' +
    i18n.t(`reactive_power_type.${rawData.reactive_power_type}`)
  );
}

function camelCaseToReadable(text) {
  let result = '';
  for (const char of [...text]) {
    if ('ABCDEFGHIJKLMNOPQRSTUVWXYZ'.includes(char)) {
      result += ' ';
    }
    if (!result) {
      result += char.toUpperCase();
    } else {
      result += char;
    }
  }
  return result;
}

function installerExpiryDateObject(rawData) {
  return 'Gültig bis ' + formatDate(rawData.expiry);
}

function contactPersonObject(contactPerson) {
  if (!contactPerson) {
    return '-';
  }

  const {
    title,
    name,
    surname,
    function_in_company,
    phone_number,
    mobile_number,
    email,
  } = contactPerson;

  return `
    ${title} ${name} ${surname} (${function_in_company})<br>
    Telefon: ${phone_number || '-'}<br>
    Mobilnummer: ${mobile_number}<br>
    E-Mail: ${email || '-'}<br>
  `;
}

function constructionWorkNEWObject(rawData) {
  const stromCompany = rawData.assembly_electricity_company
    ? '<br>Montage Strom: <br>' +
      constructionCompanyObject(rawData.assembly_electricity_company)
    : '';
  const gasCompany = rawData.assembly_gas_water_company
    ? '<br> Montage Gas Wasser: <br>' +
      constructionCompanyObject(rawData.assembly_gas_water_company)
    : '';
  const constructionWork =
    'Baubeauftragter NEW: <br>' +
    constructionManagerObject(rawData.internal_construction_management) +
    '<br> Montage Tiefbau: <br>' +
    constructionCompanyObject(rawData.groundwork_company) +
    stromCompany +
    gasCompany;
  return constructionWork;
}

function constructionCompanyObject(rawData) {
  const companyObject = rawData.company_name
    ? rawData.company_name +
      '<br>' +
      rawData.contact_person +
      '<br>' +
      'Starttermin: ' +
      formatDate(rawData.construction_planned_date.dates[0]) +
      ' ' +
      rawData.construction_planned_date.start_time.start_hour +
      ':' +
      rawData.construction_planned_date.start_time.start_minute +
      ' Uhr' +
      '<br>' +
      'Endtermin: ' +
      (rawData.construction_planned_date.dates[1]
        ? formatDate(rawData.construction_planned_date.dates[1])
        : formatDate(rawData.construction_planned_date.dates[0])) +
      ' ' +
      rawData.construction_planned_date.end_time.end_hour +
      ':' +
      rawData.construction_planned_date.end_time.end_minute +
      ' Uhr' +
      '<br>'
    : 'Nicht ausgewählt' + '<br>';
  return companyObject;
}

function constructionManagerObject(rawData) {
  const managerObject = rawData.construction_manager
    ? rawData.construction_manager +
      '<br>' +
      'Starttermin: ' +
      formatDate(rawData.construction_planned_date.dates[0]) +
      ' ' +
      rawData.construction_planned_date.start_time.start_hour +
      ':' +
      rawData.construction_planned_date.start_time.start_minute +
      ' Uhr' +
      '<br>' +
      'Endtermin: ' +
      (rawData.construction_planned_date.dates[1]
        ? formatDate(rawData.construction_planned_date.dates[1])
        : formatDate(rawData.construction_planned_date.dates[0])) +
      ' ' +
      rawData.construction_planned_date.end_time.end_hour +
      ':' +
      rawData.construction_planned_date.end_time.end_minute +
      ' Uhr' +
      '<br>'
    : 'Nicht ausgewählt' + '<br>';
  return managerObject;
}

function formatDate(date) {
  return dayjs(date).format('DD.MM.YYYY');
}

function sapDataObject(rawData) {
  return (
    'Geschäftspartner-ID: ' +
    rawData.sap.partner +
    '<br>' +
    'Verbrauchsstelle-ID: ' +
    rawData.sap.vstelle +
    '<br>' +
    'Anschlussobjekt-ID: ' +
    rawData.sap.haus +
    '<br>' +
    'Geräteplatz-ID: ' +
    rawData.sap.devloc +
    '<br>' +
    'Adressdaten-Key: ' +
    rawData.sap.addrnumber
  );
}

function errorObject(rawData) {
  let error = '';
  try {
    error = JSON.parse(rawData.error);
    error = JSON.parse(error.errorMessage);
    error = error.msg.msg_lines;
  } catch (e) {
    error = String(rawData.error);
  }
  return error;
}

function productObject(rawData) {
  return `${i18n.t('history.product')} ${mapProductName(rawData.product)}`;
}

function installationsObject(rawData) {
  let presentable_installations = 'Installationen:';
  const el_inst = rawData['electric_installations'];
  if (el_inst) {
    presentable_installations += '<br>';
    for (const e_installation of Object.keys(el_inst)) {
      switch (e_installation) {
        case 'heat_pump_hot_water':
          el_inst[e_installation]
            ? (presentable_installations += 'Wärmepumpe mit Warmwasser, ')
            : '';
          break;
        case 'heat_pump_power_rating':
          el_inst[e_installation]
            ? (presentable_installations += `Wärmepumpe: <b>${el_inst[e_installation]}kW</b>, `)
            : '';
          break;
        case 'heat_pump':
          el_inst[e_installation]
            ? (presentable_installations += 'Wärmepumpe, ')
            : '';
          break;
        case 'e_charging_station':
          el_inst[e_installation]
            ? (presentable_installations += 'E-Ladesäule, ')
            : '';
          break;
        case 'water_heater':
          el_inst[e_installation]
            ? (presentable_installations += 'Durchlauferhitzer, ')
            : '';
          break;
        case 'water_heaters_power_rating':
          el_inst[e_installation]
            ? (presentable_installations += `Durchlauferhitzer: <b>${el_inst[e_installation]}kW</b>, `)
            : '';
          break;
      }
    }
    if (presentable_installations.endsWith(', ')) {
      presentable_installations = presentable_installations.substr(
        0,
        presentable_installations.length - 2
      );
    }
  }

  const gas_inst = rawData['gas_installations'];
  if (gas_inst) {
    presentable_installations += '<br>';
    for (const g_installation of Object.keys(gas_inst)) {
      switch (g_installation) {
        case 'gas_cooker':
          gas_inst[g_installation]
            ? (presentable_installations += 'Gasherd, ')
            : '';
          break;
        case 'cooker_gas_rating':
          gas_inst[g_installation]
            ? (presentable_installations += `Gasherd: <b>${gas_inst[g_installation]}kW</b>, `)
            : '';
          break;
        case 'terrace_connection':
          gas_inst[g_installation]
            ? (presentable_installations += 'Terrassenanschluss, ')
            : '';
          break;
        case 'gas_heating':
          gas_inst[g_installation]
            ? (presentable_installations += 'Gasheizung, ')
            : '';
          break;
        case 'selected_heating':
          switch (gas_inst[g_installation]) {
            case 'central_heating_with_hot_water':
              presentable_installations += 'Zentralheizung mit Warmwasser: ';
              break;
            case 'central_heating_without_hot_water':
              presentable_installations += 'Zentralheizung ohne Warmwasser: ';
              break;
            case 'self_contained_central_heating_with_hot_water':
              presentable_installations += 'Gasetagenheizung mit Warmwasser: ';
              break;
            case 'self_contained_central_heating_without_hot_water':
              presentable_installations += 'Gasetagenheizung ohne Warmwasser: ';
              break;
            default:
              break;
          }
          presentable_installations += `<b>${gas_inst.heating_gas_rating}kW</b>, `;
          break;
        case 'gas_heating_hot_water':
          gas_inst[g_installation]
            ? (presentable_installations += 'Gasheizung mit Warmwasser, ')
            : '';
          break;
      }
    }
    if (presentable_installations.endsWith(', ')) {
      presentable_installations = presentable_installations.substr(
        0,
        presentable_installations.length - 2
      );
    }
  }

  const water_inst = rawData['water_installations'];
  if (water_inst) {
    presentable_installations += '<br>';
    for (const w_installation of Object.keys(water_inst)) {
      switch (w_installation) {
        case 'apartment_units_water_ratings':
          water_inst[w_installation]
            ? (presentable_installations += `Durchfluss: <b>${water_inst[w_installation]}m³/h</b>, `)
            : '';
          break;
        case 'file_name':
          water_inst[w_installation]
            ? (presentable_installations += `Volumenstromberechnung: <b>${water_inst[w_installation]}</b>, `)
            : '';
          break;
        case 'self_supply':
          switch (water_inst[w_installation]) {
            case 'existing':
              presentable_installations +=
                'Eigenversorgung: <b>vorhanden</b>, ';
              break;
            case 'planned':
              presentable_installations += 'Eigenversorgung: <b>geplant</b>, ';
              break;
            default:
              break;
          }
          break;
      }
    }
    if (presentable_installations.endsWith(', ')) {
      presentable_installations = presentable_installations.substr(
        0,
        presentable_installations.length - 2
      );
    }
  }
  return presentable_installations;
}

function priceObject(rawData, product) {
  let priceObject = rawData.own_contribution_selected
    ? 'Eigenleistung <b>ist ausgewählt</b>.'
    : 'Eigenleistung <b>ist nicht ausgewählt</b>.';
  priceObject +=
    rawData.preferred_network_connection === true
      ? '<br>Vorgezogener Netzanschluss als Baustromanschluss <b>ist ausgewählt</b>.'
      : '<br>Vorgezogener Netzanschluss als Baustromanschluss <b>ist nicht ausgewählt</b>.';
  if (product === 'eMobility') {
    priceObject += rawData.e_charging_station_controllable_selected
      ? '<br>Förderung der E-Mobilität – steuerbare Ladeeinrichtung <b>ist ausgewählt</b>.'
      : '<br>Förderung der E-Mobilität – steuerbare Ladeeinrichtung <b>ist nicht ausgewählt</b>.';
  } else {
    priceObject += rawData.requires_grid_crosspoint
      ? '<br>Netzanschlussraum ist <b>über 25 Meter</b> von der Grundstücksgrenze entfernt.'
      : '<br>Netzanschlussraum ist <b>unter 25 Meter</b> von der Grundstücksgrenze entfernt.';
    if (rawData.gas_less_than_5m_selected !== undefined) {
      priceObject += rawData.gas_less_than_5m_selected
        ? '<br>Förderung für den Netzanschluss Erdgas <b>ist ausgewählt</b>.'
        : '<br>Förderung für den Netzanschluss Erdgas <b>ist nicht ausgewählt</b>.';
    }
  }

  return priceObject;
}

function fileObject(rawData) {
  let files = 'Baupläne: <br>';
  rawData.uploaded_file_names_blueprint
    ? rawData.uploaded_file_names_blueprint.forEach((file) => {
        if (file !== '') {
          files += file + ', ';
        }
      })
    : '';
  rawData.uploaded_file_names_cadastral
    ? rawData.uploaded_file_names_cadastral.forEach((file) => {
        if (file !== '') {
          files += file + ', ';
        }
      })
    : '';
  if (files.endsWith(', ')) {
    files = files.substr(0, files.length - 2);
  }
  return files;
}

function orderEmobilityFileObject(rawData) {
  if (rawData.file_uploads.length === 0) {
    return 'Keine Ladepläne hochgeladen.';
  }
  let files = 'Ladepläne: <br>';
  rawData.file_uploads.forEach((element) => {
    files += element + ', ';
  });
  if (files.endsWith(', ')) {
    files = files.substr(0, files.length - 2);
  }
  return files;
}

function installerFileObject(fileNames) {
  const allNull = Object.values(fileNames).every((value) => value === null);
  const isInternal = store.getters.getSelectedInstaller.internal;

  if (allNull) {
    return '';
  }
  let installerFilesName = '<strong>Fachliche Nachweise</strong>' + '<br>';
  // Define keys for the `else` case
  const concessionKeys = [
    'concession_electricity',
    'concession_gas',
    'concession_water',
  ];

  Object.keys(fileNames).forEach((key) => {
    const label = i18n.t(`file_types.${key}`); // Fetch label from i18n
    const value = fileNames[key] || '-'; // Fallback to "-" if null or empty

    if (isInternal) {
      // Add all keys except concessionKeys
      if (!concessionKeys.includes(key)) {
        installerFilesName += `${label}: ${value}<br>`;
      }
    } else {
      // Add only concessionKeys
      if (concessionKeys.includes(key)) {
        installerFilesName += `${label}: ${value}<br>`;
      }
    }
  });
  return installerFilesName;
}

function translateEnergyType(energyType) {
  return i18n.t(`energy_type_component.${energyType}`) || energyType;
}

function responsibleSpecialistsObject(rawData) {
  const formatValue = (value, fallback = '-') =>
    value == null || value === '' ? fallback : value;

  if (
    !rawData?.responsible_specialists ||
    !Array.isArray(rawData.responsible_specialists)
  ) {
    return '';
  }

  let result = '';

  rawData.responsible_specialists.forEach((specialist, index) => {
    const {
      name,
      surname,
      function_in_company,
      energy_types = [],
      phone_number,
      mobile_number,
      email,
    } = specialist;

    result += `
      Fachkraft ${index + 1}:<br>
      ${formatValue(name)} ${formatValue(surname)} (${formatValue(
      function_in_company
    )})<br>
      Fachkraft für: ${formatValue(
        energy_types.map(translateEnergyType).join(', ')
      )}<br>
      Telefon: ${formatValue(phone_number)}<br>
      Mobilnummer: ${formatValue(mobile_number)}<br>
      Email: ${formatValue(email, 'nicht angeben')}<br>
    `.trim();
  });

  return result;
}

function installerStatusObject(rawData) {
  if (rawData.status === 'LICENSE_REQUEST_ACTIVE') {
    return 'Status: aktiv';
  }
  if (rawData.status === 'LICENSE_REQUEST_CHECKING') {
    return 'Status: in Prüfung';
  }
  if (rawData.status === 'LICENSE_REQUEST_REFUSED') {
    return 'Status: Prüfung abgelehnt';
  }
  if (rawData.status === 'LICENSE_REQUEST_EXPIRED') {
    return 'Status: inaktiv';
  }
  if (rawData.status === 'LICENSE_REQUEST_DELETED') {
    return 'Status: gelöscht';
  }
}

function companyDataObject(companyData) {
  return (
    'Firmenname: ' +
    companyData.company_name +
    '<br>' +
    (companyData.company_address
      ? addressDataObject(companyData.company_address)
      : '') +
    'Telefon: ' +
    companyData.phone_number +
    '<br>' +
    'Entstörrufnummer: ' +
    companyData.troubleshooting_number +
    '<br>' +
    'E-Mail: ' +
    companyData.email +
    '<br>'
  );
}

function constructionWorkObject(rawData) {
  let constructionObject = dateWithUnitObject(rawData) + '<br>';
  constructionObject +=
    '<p style="margin:8px 0px 0px 0px">Tiefbau: ' +
    companyFormat(rawData.montage) +
    '<p style="margin:8px 0px 0px 0px">Montage: ' +
    companyFormat(rawData.montage) +
    internalConstructionManagementFormat(
      rawData.internal_construction_management
    );

  return constructionObject;
}

function companyFormat(rawData) {
  return (
    rawData.company_name +
    '<br>' +
    'Ansprechpartner: ' +
    rawData.contact_person +
    '</p>'
  );
}

function technicalInspectionObject(rawData) {
  return (
    'Bauadresse geprüft: ' +
    rawData.address_checked +
    '<br>' +
    'Kundenangaben geprüft: ' +
    rawData.customer_information_checked +
    '<br>' +
    'Flurkarte und Keller- bzw. Erdgeschossplan geprüft: ' +
    rawData.gis_checked +
    '<br>' +
    'Netzanschlusssituation in GIS ist technisch umzusetzen: ' +
    rawData.blueprints_checked +
    '<br>' +
    'Netzanschlusseingaben eingetragen: ' +
    rawData.connection_data_checked
  );
}

function installerInspectionObject(rawData, product) {
  let installerInspectionObject = '';
  if (product.includes('gas') || product.includes('water')) {
    installerInspectionObject +=
      'Gas/Wasser: ' +
      '<br>' +
      'Unternehmensdaten vollständig: ' +
      rawData.company_data_checked_gas_water +
      '<br>' +
      'Fachkräfte angegeben: ' +
      rawData.responsible_specialists_checked_gas_water +
      '<br>' +
      'Dokumente vollständig: ' +
      rawData.documents_checked_gas_water +
      '<br>' +
      'Fachliche Kompetenz nachgewiesen: ' +
      rawData.professional_competence_checked_gas_water +
      '<br>';
  }
  if (product.includes('electricity')) {
    installerInspectionObject +=
      'Strom: ' +
      '<br>' +
      'Unternehmensdaten vollständig: ' +
      rawData.company_data_checked_electricity +
      '<br>' +
      'Fachkräfte angegeben: ' +
      rawData.responsible_specialists_checked_electricity +
      '<br>' +
      'Dokumente vollständig: ' +
      rawData.documents_checked_electricity +
      '<br>' +
      'Fachliche Kompetenz nachgewiesen: ' +
      rawData.professional_competence_checked_electricity;
  }
  return installerInspectionObject;
}

function internalConstructionManagementFormat(rawData) {
  let internal_construction_management = '<br>';
  internal_construction_management = `<p style="margin:8px 0px 0px 0px"> Regionalteam: ${rawData.regional_team}
  <br> Baubegleitung: ${rawData.construction_supervision} 
  <br> Bauleiter: ${rawData.construction_manager}  </p>`;

  return internal_construction_management;
}

function concessionDivisionObject(concessionDivisionsArray) {
  if (
    !Array.isArray(concessionDivisionsArray) ||
    concessionDivisionsArray.length === 0
  ) {
    return 'Installateurausweissparte: Keine';
  }

  const divisionMap = {
    gas: 'Erdgas',
    water: 'Trinkwasser',
    electricity: 'Strom',
  };

  const concessionDivision = concessionDivisionsArray
    .map((item) => divisionMap[item] || item)
    .join(', ');

  return `Installateurausweissparte:<br>${concessionDivision}`;
}

function existingSystemsObject(rawData) {
  const existingSystems = rawData?.existing_systems;
  const fields = [
    {
      name: i18n.t('feed_in_system_details.photovoltaic_system'),
      power: existingSystems?.pv_system_W
        ? existingSystems?.pv_system_W / 1000
        : null,
      unit: i18n.t('feed_in_system_details.kWp'),
      date_label: i18n.t('feed_in_system_details.commissioning_date'),
      date: formatDateStringOrDash(
        existingSystems?.pv_system_implementing_date
      ),
    },
    {
      name: i18n.t('feed_in_system_details.plug_in_photovoltaic_system'),
      power: existingSystems?.plugin_pv_system_W,
      unit: i18n.t('feed_in_system_details.W'),
      date_label: i18n.t('feed_in_system_details.commissioning_date'),
      date: formatDateStringOrDash(
        existingSystems?.plugin_pv_system_implementing_date
      ),
    },
    {
      name: i18n.t('feed_in_system_details.cogeneration_unit'),
      power: existingSystems?.cogeneration_VA
        ? existingSystems?.cogeneration_VA / 1000
        : null,
      unit: i18n.t('feed_in_system_details.kVA'),
      date_label: i18n.t('feed_in_system_details.commissioning_date'),
      date: formatDateStringOrDash(
        existingSystems?.cogeneration_implementing_date
      ),
    },
    {
      name: i18n.t('feed_in_system_details.wind_power'),
      power: existingSystems?.wind_power_VA
        ? existingSystems?.wind_power_VA / 1000
        : null,
      unit: i18n.t('feed_in_system_details.kVA'),
      date_label: i18n.t('feed_in_system_details.commissioning_date'),
      date: formatDateStringOrDash(
        existingSystems?.wind_power_implementing_date
      ),
    },
    {
      name: i18n.t('feed_in_system_details.standby_system'),
      power: existingSystems?.standby_system_VA
        ? existingSystems?.standby_system_VA / 1000
        : null,
      unit: i18n.t('feed_in_system_details.kVA'),
      date_label: i18n.t('feed_in_system_details.commissioning_date'),
      date: formatDateStringOrDash(
        existingSystems?.standby_system_implementing_date
      ),
    },
    {
      name: existingSystems?.other_system_name,
      power: existingSystems?.other_system_VA
        ? existingSystems?.other_system_VA / 1000
        : null,
      unit: i18n.t('feed_in_system_details.kVA'),
      date_label: i18n.t('feed_in_system_details.commissioning_date'),
      date: formatDateStringOrDash(
        existingSystems?.other_system_implementing_date
      ),
    },
  ];
  const output = fields
    .filter((field) => !!field.power) // Filter out fields with falsy values
    .map(
      (field) =>
        `<div style="margin-bottom: 8px">${field.name}: ${field.power} ${field.unit}<br>${field.date_label}: ${field.date}</div>`
    )
    .join('');

  return rawData.has_existing_systems
    ? output
    : i18n.t('feed_in_system_details.no_existing_system');
}

function pvLocationObject(rawData) {
  const locationTypes = {
    roof: 'Anlage auf dem Dach',
    facade: 'Anlage an der Fassade',
    detached: 'Freistehende Anlage',
  };
  return [
    {
      exists: !!rawData.pv_location,
      title: 'Anlagentyp',
      value: locationTypes[rawData.pv_location],
    },
    {
      exists: !!rawData.pv_location_coordinates,
      title: 'Koordinaten',
      value: `${rawData.pv_location_coordinates?.lat}, ${rawData.pv_location_coordinates?.lng}`,
    },
  ]
    .filter((part) => part.exists)
    .reduce((acc, part) => acc.concat(`${part.title}: ${part.value} <br>`), '');
}

function financeDataObject({ finance_data }) {
  if (!finance_data || !finance_data.account_data) {
    return '-';
  }
  const kontoinhaber = finance_data.account_data.account_owner;
  const IBAN = insertSpacesToIBAN(finance_data.account_data.iban);
  const Bank = finance_data.account_data.bank;
  const BIC = finance_data.account_data.bic;
  const kleinunternehmerText = finance_data.is_small_company
    ? `${i18n.t('finance_data_step.small_company')}`
    : `${i18n.t('finance_data_step.no_small_company')}`;
  const steuernummer = finance_data.tax_data.tax_number ?? '-';
  const ustIdNr = finance_data.tax_data.vat_number ?? '-';
  const isReseller = finance_data.is_reseller
    ? `${i18n.t('finance_data_step.yes')}`
    : `${i18n.t('finance_data_step.no')}`;
  const ustFileName = `${i18n.t('finance_data_step.ust_file_name')}: ${
    finance_data.ust_proof_file_name ?? '-'
  }`;
  const assignmentNoticeFileName = `${i18n.t(
    'finance_data_step.assignment_notice_file_name'
  )}: ${finance_data.assignment_notice_file_name ?? '-'}`;

  const output =
    `${i18n.t('finance_data_step.account_holder')}: ${kontoinhaber}${newLine}` +
    `${i18n.t('finance_data_step.iban')}: ${IBAN}${newLine}` +
    `${i18n.t('finance_data_step.bank_name')}: ${Bank}${newLine}` +
    `${i18n.t('finance_data_step.bic')}: ${BIC}${newLine}` +
    `${kleinunternehmerText}${newLine}` +
    `${i18n.t('finance_data_step.tax_id')}: ${steuernummer}${newLine}` +
    `${i18n.t('finance_data_step.turnover_tax_id')}.: ${ustIdNr}${newLine}` +
    `${i18n.t('finance_data_step.reseller')}: ${isReseller}${newLine}` +
    `${ustFileName}${newLine}` +
    `${assignmentNoticeFileName}`;
  return output;
}

function pvRequestInspectionObject({ pvrequest_inspection }) {
  if (!pvrequest_inspection) {
    return;
  }

  const fields = [
    {
      label: i18n.t('technical_inspection.tension'),
      value:
        pvrequest_inspection.voltage === 'low'
          ? i18n.t('technical_inspection.low_voltage')
          : pvrequest_inspection.voltage,
    },
    {
      label: i18n.t('technical_inspection.customer_station'),
      value:
        pvrequest_inspection.customer_station === 'new'
          ? i18n.t('technical_inspection.new')
          : pvrequest_inspection.customer_station === 'existing'
          ? i18n.t('technical_inspection.existing')
          : '',
    },
    {
      label: i18n.t('technical_inspection.plan'),
      value: removeUUIDFromFileName(
        pvrequest_inspection.customer_station_plan_filename
      ),
    },
    {
      label: i18n.t('technical_inspection.heading_in_additions'),
      value: pvrequest_inspection?.addition?.title,
    },
    {
      label: i18n.t('technical_inspection.comment_in_additions'),
      value: pvrequest_inspection?.addition?.comment,
    },
  ];

  const output = fields
    .filter((field) => field.value) // Filter out fields with falsy values
    .map((field) => `${field.label}: ${field.value}<br>`)
    .join('');

  return output;
}

function pvImplementationInspectionObject({
  pvimplementation_inspection,
  pvrequest_inspection,
}) {
  if (!pvimplementation_inspection) {
    return '-';
  }

  const fields = [
    {
      label: i18n.t('technical_inspection.measure_concept_checked'),
      value: pvimplementation_inspection?.measure_concept_checked
        ? i18n.t('feed_in_system_details.yes')
        : i18n.t('feed_in_system_details.no'),
    },
    {
      label: i18n.t('technical_inspection.e_plan_checked'),
      value: pvimplementation_inspection?.e_plan_checked
        ? i18n.t('feed_in_system_details.yes')
        : i18n.t('feed_in_system_details.no'),
    },
    {
      label: i18n.t('technical_inspection.tension'),
      value:
        pvrequest_inspection?.voltage === 'low'
          ? i18n.t('technical_inspection.low_voltage')
          : pvrequest_inspection?.voltage,
    },
    {
      label: i18n.t('technical_inspection.customer_station'),
      value:
        pvrequest_inspection?.customer_station === 'new'
          ? i18n.t('technical_inspection.new')
          : pvrequest_inspection?.customer_station === 'existing'
          ? i18n.t('technical_inspection.existing')
          : '',
    },
    {
      label: i18n.t('technical_inspection.plan'),
      value: removeUUIDFromFileName(
        pvrequest_inspection?.customer_station_plan_filename
      ),
    },
    {
      label: i18n.t(
        'technical_inspection.measurement_data.fields.market_location_provider'
      ),
      value: pvimplementation_inspection?.market_location_provider,
    },
    {
      label: i18n.t(
        'technical_inspection.measurement_data.fields.measuring_location_provider'
      ),
      value: pvimplementation_inspection?.measuring_location_provider,
    },
    {
      label: i18n.t(
        'technical_inspection.measurement_data.fields.counting_method'
      ),
      value: pvimplementation_inspection?.counting_method,
    },
    {
      label: i18n.t('technical_inspection.heading_in_additions'),
      value: pvimplementation_inspection?.addition?.title,
    },
    {
      label: i18n.t('technical_inspection.comment_in_additions'),
      value: pvimplementation_inspection?.addition?.comment,
    },
  ];

  const output = fields
    .filter((field) => field.value) // Filter out fields with falsy values
    .map((field) => `${field.label}: ${field.value}<br>`)
    .join('');

  return output;
}

function getTranslatedValue(inputValue, listValues) {
  return listValues.find((option) => option.value === inputValue)?.translation;
}

function pvImplementationProtokollInspectionObject(
  { pvimplementationprotocol_inspection },
  eventName
) {
  if (!pvimplementationprotocol_inspection) {
    return;
  }
  let fields = [];
  if (eventName !== 'updatePVCountersData') {
    fields = fields.concat([
      {
        label: i18n.t('technical_inspection.technical_specifications_checked'),
        value:
          pvimplementationprotocol_inspection?.technical_specifications_checked
            ? i18n.t('feed_in_system_details.yes')
            : i18n.t('feed_in_system_details.no'),
      },
      {
        label: i18n.t('technical_inspection.implementing_date_checked'),
        value: pvimplementationprotocol_inspection?.implementing_date_checked
          ? i18n.t('feed_in_system_details.yes')
          : i18n.t('feed_in_system_details.no'),
      },
      {
        label: i18n.t(
          'technical_inspection.measurement_data.fields.market_location'
        ),
        value: pvimplementationprotocol_inspection?.market_location,
      },
      {
        label: i18n.t('technical_inspection.heading_in_additions'),
        value: pvimplementationprotocol_inspection?.addition?.title,
      },
      {
        label: i18n.t('technical_inspection.comment_in_additions'),
        value: pvimplementationprotocol_inspection?.addition?.comment,
      },
    ]);
  }

  pvimplementationprotocol_inspection?.counters?.forEach((counter, index) => {
    fields.push(
      counter?.counter_id
        ? {
            label: `<strong>${i18n.t('feed_in_system_details.counter_data')} ${
              index + 1
            }</strong>`,
            value: 'COUNTER_TITLE',
          }
        : '',
      {
        label: i18n.t('technical_inspection.type_of_usage'),
        value: getTranslatedValue(
          counter?.type_of_usage,
          type_of_usage_options
        ),
      },
      {
        label: i18n.t('technical_inspection.measuring_location'),
        value: counter?.measuring_location,
      },
      {
        label: i18n.t('feed_in_system_details.counter_number'),
        value: counter?.counter_id,
      },
      {
        label: i18n.t('feed_in_system_details.valid_from'),
        value: formatDate(counter?.valid_from),
      },
      {
        label: i18n.t('feed_in_system_details.couter_type'),
        value: counter?.counter_type,
      },
      {
        label: i18n.t('technical_inspection.charge_type'),
        value: counter?.charge_type,
      },
      {
        label: i18n.t('feed_in_system_details.current_direction'),
        value: getTranslatedValue(
          counter?.current_direction,
          CURRENT_DIRECTION_LIST
        ),
      },
      {
        label: i18n.t('feed_in_system_details.mount_type'),
        value:
          counter?.mount_type === 'DIN' ? 'Hutschienen' : counter?.mount_type,
      },
      {
        label: i18n.t('feed_in_system_details.measuring_device_type'),
        value: counter?.measuring_device_type,
      },
      {
        label: i18n.t('feed_in_system_details.obis_key'),
        value: counter?.obis_key,
      },
      {
        label: i18n.t('feed_in_system_details.pre_decimal_places'),
        value:
          counter?.pre_decimal_places === 0
            ? 0
            : counter?.pre_decimal_places || '-',
      },
      {
        label: i18n.t('feed_in_system_details.decimal_places'),
        value:
          counter?.decimal_places === 0 ? 0 : counter?.decimal_places || '-',
      }
    );
  });

  const output = fields
    .filter((field) => field.value !== null && field.value !== undefined) // Filter out fields with falsy values except 0
    .map((field) =>
      field.value === 'COUNTER_TITLE'
        ? `${field.label}<br>`
        : `${field.label}: ${field.value}<br>`
    )
    .join('');

  return output;
}

function formatDateStringOrDash(date) {
  return date ? formatDate(date, 'DD.MM.YYYY') : '-';
}

function powerFlowMonitoringObject(rawData) {
  const isPowerFlowMonitored = `${i18n.t(
    'power_flow_monitoring.is_power_flow_monitored'
  )}: ${
    rawData.power_flow_monitoring?.is_power_flow_monitored
      ? i18n.t('power_flow_monitoring.yes')
      : i18n.t('power_flow_monitoring.no')
  }`;
  const monitoredPowerFlowInW = `${i18n.t(
    'power_flow_monitoring.monitored_power_flow_in_w'
  )}: ${rawData.power_flow_monitoring?.monitored_power_flow_W / 1000} ${i18n.t(
    'power_flow_monitoring.kW'
  )}`;

  return `${isPowerFlowMonitored} <br> ${
    rawData.power_flow_monitoring?.is_power_flow_monitored
      ? monitoredPowerFlowInW
      : ''
  }`;
}

function symmetryRequirementObject(rawData) {
  const symetryObject = rawData?.symmetry_requirement;

  const type = i18n.t(`symmetry_requirement.${symetryObject?.type}`);

  const isSinglePhase =
    symetryObject?.type === 'single_phase_connected_inverter';

  const outerConductorL1 = isSinglePhase
    ? `<br> ${i18n.t('symmetry_requirement.outer_conductor_l1')}: ${
        symetryObject?.outer_conductor_l1_VA / 1000
      } ${i18n.t('symmetry_requirement.kVA')}`
    : '';

  const outerConductorL2 = isSinglePhase
    ? `<br> ${i18n.t('symmetry_requirement.outer_conductor_l2')}: ${
        symetryObject?.outer_conductor_l2_VA / 1000
      } ${i18n.t('symmetry_requirement.kVA')}`
    : '';

  const outerConductorL3 = isSinglePhase
    ? `<br> ${i18n.t('symmetry_requirement.outer_conductor_l3')}: ${
        symetryObject?.outer_conductor_l3_VA / 1000
      } ${i18n.t('symmetry_requirement.kVA')}`
    : '';

  return `${type} ${outerConductorL1} ${outerConductorL2} ${outerConductorL3}`;
}

function marketLocationObject(rawData) {
  const fields = [
    {
      label: i18n.t(
        'technical_inspection.measurement_data.fields.market_location_provider'
      ),
      value: rawData.pvimplementation_inspection?.market_location_provider,
    },
    {
      label: i18n.t(
        'technical_inspection.measurement_data.fields.market_location'
      ),
      value: rawData.pvimplementationprotocol_inspection?.market_location,
    },
  ];

  const output = fields
    .map((field) => `${field.label}: ${field.value}<br>`)
    .join('');

  return output;
}
function measurementLocationObject(rawData) {
  const fields = [
    {
      label: i18n.t(
        'technical_inspection.measurement_data.fields.counting_method'
      ),
      value: rawData.pvimplementation_inspection?.counting_method,
    },
    {
      label: i18n.t(
        'technical_inspection.measurement_data.fields.measuring_location_provider'
      ),
      value: rawData.pvimplementation_inspection?.measuring_location_provider,
    },
  ];

  const output = fields
    .map((field) => `${field.label}: ${field.value}<br>`)
    .join('');

  return output;
}

function voltageLevelObject(rawData) {
  const voltage = rawData.pvrequest_inspection?.voltage;
  const voltageTranslation = voltage === 'low' ? 'Niederspannung' : voltage;
  return `${i18n.t('technical_inspection.voltage')}: ${voltageTranslation}`;
}

function customerStationObject(rawData) {
  const translateCustomerStation = (station) => {
    if (station === 'new') {
      return 'neu';
    } else if (station === 'existing') {
      return 'Vorhanden';
    } else {
      return station ?? '-';
    }
  };

  const fields = [
    {
      label: i18n.t('technical_inspection.customer_station'),
      value: translateCustomerStation(
        rawData.pvrequest_inspection?.customer_station
      ),
    },
    {
      label: i18n.t('technical_inspection.plan'),
      value: removeUUIDFromFileName(
        rawData.pvrequest_inspection?.customer_station_plan_filename ?? '-'
      ),
    },
  ];

  const output = fields
    .map((field) => `${field.label}: ${field.value}<br>`)
    .join('');

  return output;
}

function inverterPowerObject(rawData) {
  const inverterPerformance = `${i18n.t(
    'photovoltaic_system_step.inverter_power'
  )}: ${
    rawData.pv_system_infos.inverter_performance_W > 0
      ? `${rawData.pv_system_infos.inverter_performance_W / 1000} ${i18n.t(
          'photovoltaic_system_step.kW'
        )}`
      : '-'
  } <br>`;

  return inverterPerformance;
}

function installerCompanyDataObject(companyData) {
  return `
    ${
      companyData.company_name
        ? 'Firmenname: ' + companyData.company_name + '<br>'
        : ''
    }
    ${
      companyData.company_address
        ? addressDataObject(companyData.company_address)
        : ''
    }
    ${
      companyData.phone_number
        ? 'Telefon: ' + companyData.phone_number + '<br>'
        : ''
    }
    ${
      companyData.troubleshooting_number
        ? 'Entstörrufnummer: ' + companyData.troubleshooting_number + '<br>'
        : ''
    }
    ${companyData.email ? 'E-Mail: ' + companyData.email + '<br>' : ''}
  `;
}

function installerConsentsObject(consentsObject) {
  if (consentsObject.user_note) {
    delete consentsObject.user_note;
  }
  if (!consentsObject || typeof consents !== 'object') {
    return '';
  }

  const translationMap = {
    installer_contract_and_technical_compliance: i18n.t(
      'consents.installer_contract_and_technical_compliance'
    ),
    publication_and_contact: i18n.t('consents.publication_and_contact'),
  };

  let result = 'Einwilligung:<br>';

  for (const [key, value] of Object.entries(consentsObject)) {
    const translatedKey = translationMap[key] || key;
    const translatedValue = value ? 'Ja' : 'Nein';
    result += `${translatedKey}: ${translatedValue}<br>`;
  }

  return result.trim();
}

function installerRequirementsObject(requirementsObject) {
  if (!requirementsObject || typeof requirementsObject !== 'object') {
    return '';
  }

  const electricityTranslations = {
    VDE_regulations: 'VDE-Vorschriften ',
    measuring_device: 'Messgeräte',
  };

  const gasWaterTranslations = {
    measuring_device: 'Messgeräte',
    DVGW_worksheets: 'DVGW Arbeitsblätter',
    G_600_TRGI: 'G 600 TRGI',
    DIN_1988: 'DIN_1988',
    DIN_EN_806: 'DIN_EN_806',
    DIN_EN_1717: 'DIN_EN_1717',
    security_bag: 'Sicherheitstasche',
  };

  let result = '<strong>Anforderungen:</strong><br>';

  if (
    requirementsObject.electricity_installations &&
    Array.isArray(requirementsObject.electricity_installations)
  ) {
    result += 'Elektroinstallationen: ';
    result +=
      requirementsObject.electricity_installations
        .map((item) => electricityTranslations[item] || item)
        .join(', ') || '-';
    result += '<br>';
  }

  if (
    requirementsObject.gas_water_installations &&
    Array.isArray(requirementsObject.gas_water_installations)
  ) {
    result += 'Gas-/Wasserinstallationen: ';
    result +=
      requirementsObject.gas_water_installations
        .map((item) => gasWaterTranslations[item] || item)
        .join(', ') || '-';
    result += '<br>';
  }

  return result.trim();
}

function resendPVStorageMStRDReminderObject(rawData) {
  const storageMStRDataLastReminder = new Date(
    rawData.storage_maStR_data_last_reminder
  );

  const storageMStRDataLastReminderFormatted =
    storageMStRDataLastReminder.toLocaleDateString('de', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }) +
    ' ' +
    storageMStRDataLastReminder.toLocaleString('de', {
      hour: '2-digit',
      minute: '2-digit',
    });

  return `
  <strong>Versandzeitpunkt an Kunden:</strong><br>
  ${storageMStRDataLastReminderFormatted}
  `;
}
function resendInstallerECReminderObject(rawData) {
  const reminderDate = new Date(rawData.extend_any_expiry_date_last_reminder);
  const reminderDateFormatted =
    reminderDate.toLocaleDateString('de', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }) +
    ' ' +
    reminderDate.toLocaleString('de', {
      hour: '2-digit',
      minute: '2-digit',
    });

  return `
    <strong>Versandzeitpunkt an Installationsbetrieb:</strong><br>
    ${reminderDateFormatted}
  `;
}
function resendPVMStRDReminderObject(rawData) {
  const maStRDataLastReminder = new Date(rawData.maStR_data_last_reminder);
  const maStRDataLastReminderFormatted =
    maStRDataLastReminder.toLocaleDateString('de', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }) +
    ' ' +
    maStRDataLastReminder.toLocaleString('de', {
      hour: '2-digit',
      minute: '2-digit',
    });

  return `
  <strong>Versandzeitpunkt an Kunden:</strong><br>
  ${maStRDataLastReminderFormatted}
  `;
}

function resendPVFDReminderObject(rawData) {
  const financeDataLastReminder = new Date(rawData.finance_data_last_reminder);
  const financeDataLastReminderFormatted =
    financeDataLastReminder.toLocaleDateString('de', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }) +
    ' ' +
    financeDataLastReminder.toLocaleString('de', {
      hour: '2-digit',
      minute: '2-digit',
    });

  return `
  <strong>Versandzeitpunkt an Kunden:</strong><br>
  ${financeDataLastReminderFormatted}
  `;
}

function resendPVIReminderObject(rawData) {
  const implementationPendingReminder = new Date(
    rawData.implementation_pending_reminder
  );
  const implementationPendingReminderFormatted =
    implementationPendingReminder.toLocaleDateString('de', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }) +
    ' ' +
    implementationPendingReminder.toLocaleString('de', {
      hour: '2-digit',
      minute: '2-digit',
    });

  return `
  <strong>Versandzeitpunkt an Kunden:</strong><br>
  ${implementationPendingReminderFormatted}
  `;
}
function resendPVICEIReminderObject(rawData) {
  const installationCompanyElectricityIdLastReminder = new Date(
    rawData.installation_company_electricity_id_last_reminder
  );
  const installationCompanyElectricityIdLastReminderFormatted =
    installationCompanyElectricityIdLastReminder.toLocaleDateString('de', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }) +
    ' ' +
    installationCompanyElectricityIdLastReminder.toLocaleString('de', {
      hour: '2-digit',
      minute: '2-digit',
    });

  return `
  <strong>Versandzeitpunkt an Kunden:</strong><br>
  ${installationCompanyElectricityIdLastReminderFormatted}
  `;
}
function concessionObject(rawData) {
  let result = '';
  if (rawData.company_data) {
    result +=
      '<strong>Unternehmensdaten:</strong><br>' +
      installerCompanyDataObject(rawData.company_data) +
      '\n';
  }
  if (rawData.responsible_specialists) {
    result +=
      '<strong>Verantwortliche Fachkräfte</strong><br>' +
      responsibleSpecialistsObject(rawData) +
      '\n';
  }
  if (rawData.contact_person) {
    result +=
      '<strong>Ansprechpartner:</strong><br>' +
      contactPersonObject(rawData.contact_person) +
      '\n';
  }
  if (rawData.concession_divison) {
    result += concessionDivisionObject(rawData.concession_divison) + '\n';
  }
  if (rawData.file_names) {
    result += installerFileObject(rawData.file_names) + '\n';
  }
  if (rawData.consents) {
    result += installerConsentsObject(rawData.consents) + '\n';
  }
  if (rawData.requirements) {
    result += installerRequirementsObject(rawData.requirements) + '\n';
  }

  return result.trim();
}

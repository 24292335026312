<template>
  <div>
    <!-- Blindleistungsart -->
    <RowItem
      v-if="feedInSystem.reactive_power_type"
      first
      class="row-item"
      :title="$t('feed_in_system_details.reactive_power_type')"
      :editable="!hasStatusOrderTransmitted"
      @edit="
        $emit('edit-component', {
          component: 'ReactivePowerSelectorComponent',
          location: 'MeasurementData',
          properties: feedInSystem,
        })
      "
    >
      <div v-html="feedInSystem.reactive_power_type"></div>
    </RowItem>
    <!-- Spannungsebene Kundenstation -->
    <RowItem
      class="row-item"
      :first="!feedInSystem.reactive_power_type"
      field="updatePVVoltageLevel"
      :title="$t('feed_in_system_details.voltage_level')"
      :editable="canEditCustomerStationAndVoltage && !hasStatusOrderTransmitted"
      @edit="
        $emit('edit-component', {
          component: 'VoltageLevelComponent',
          location: 'VoltageLevel',
          properties: feedInSystem,
        })
      "
    >
      <div>{{ translateVoltageLevel }}</div>
    </RowItem>
    <!-- Kundenstation -->
    <RowItem
      class="row-item"
      field="updatePVCustomerStation"
      :title="$t('feed_in_system_details.customer_station')"
      :editable="canEditCustomerStationAndVoltage && !hasStatusOrderTransmitted"
      @edit="
        $emit('edit-component', {
          component: 'CustomerStationComponent',
          location: 'CustomerStation',
          properties: feedInSystem,
        })
      "
    >
      <div>{{ translateCustomerStation }}</div>
    </RowItem>
    <!-- Messlokation -->
    <RowItem
      class="row-item"
      field="updatePVMeasurementLocation"
      :title="`<strong>${$t(
        'feed_in_system_details.measurement_location'
      )}</strong> <br> ${$t(
        'feed_in_system_details.measurement_location_fields'
      )}`"
      :editable="
        (feedInSystem.status === feedInStatuses.in_impl_conf.value ||
          feedInSystem.status === feedInStatuses.fulfilled.value ||
          feedInSystem.status === feedInStatuses.fulfilled_insp.value ||
          feedInSystem.status === feedInStatuses.fulfilled_conf.value) &&
        !hasStatusOrderTransmitted
      "
      @edit="
        $emit('edit-component', {
          component: 'MeasurementLocationComponent',
          location: 'MeasurementData',
          properties: feedInSystem,
        })
      "
    >
      <div v-html="presentableFeedInSystem.measurementLocation"></div>
    </RowItem>
    <!-- Marktlokation -->
    <RowItem
      last
      class="row-item"
      field="updatePVMarketLocation"
      :title="`<strong>${$t(
        'feed_in_system_details.market_location'
      )}</strong><br>${$t('feed_in_system_details.market_location_fields')}`"
      :editable="
        feedInSystem.status === feedInStatuses.fulfilled_conf.value &&
        !hasStatusOrderTransmitted
      "
      @edit="
        $emit('edit-component', {
          component: 'MarketLocationComponent',
          location: 'MeasurementData',
          properties: feedInSystem,
        })
      "
    >
      <div v-html="presentableFeedInSystem.marketLocation"></div>
    </RowItem>
  </div>
</template>

<script>
import RowItem from './RowItem';
import { formatPVObject } from './transform_raw_data_to_pv_format';
import { PVJOURNEYS, FEED_IN_STATUSES } from '@/helpers/constants';

export default {
  name: 'MeasurementData',
  components: {
    RowItem,
  },
  props: {
    feedInSystem: Object,
  },
  data() {
    return {
      feedInStatuses: FEED_IN_STATUSES,
    };
  },
  created() {},
  computed: {
    presentableFeedInSystem() {
      return formatPVObject(this.feedInSystem);
    },
    isPVInbetriebnahme() {
      return this.feedInSystem.journey === PVJOURNEYS.pvi;
    },
    isPVAnfrage() {
      return this.feedInSystem.journey === PVJOURNEYS.pv;
    },
    hasStatusOrderTransmitted() {
      return this.feedInSystem.status === FEED_IN_STATUSES.transmitted.value;
    },
    canEditCustomerStationAndVoltage() {
      return (
        this.feedInSystem.status !== this.feedInStatuses.created.value &&
        this.feedInSystem.status !==
          this.feedInStatuses.critical_created.value &&
        this.feedInSystem.status !== this.feedInStatuses.tech_feas.value
      );
    },
    translateCustomerStation() {
      const customerStation =
        this.feedInSystem.pvrequest_inspection?.customer_station;
      if (customerStation === 'new') {
        return 'neu';
      } else if (customerStation === 'existing') {
        return 'Vorhanden';
      } else {
        return customerStation ?? '-';
      }
    },
    translateVoltageLevel() {
      const voltageLevel = this.feedInSystem.pvrequest_inspection?.voltage;
      if (voltageLevel === 'low') {
        return 'Niederspannung';
      } else {
        return voltageLevel ? `Mittelspannung, ${voltageLevel} ` : '-';
      }
    },
  },
};
</script>

<style scoped>
.row-item {
  margin-block: 1px;
}
</style>

import i18n from '@/plugins/i18n';

export const FINISHED_STATUS = 'finished';
export const EMPTY_STATUS = 'empty';
export const DISABLED_STATUS = 'disabled';
export const COMPANY_TITLE = 'Firma';
export const CITY_TITLE = 'Stadt/Kommune';
export const PLANT_OPERATOR = 'plant_operator';
export const NOT_ARRANGED_YET = 'not_arranged_yet';

export const MESSKONZEPTE_SMALL_TITLES = {
  'Messkonzept 1 (MK 1)': 'Volleinspeisung mit Bezug gemäß EEG / KWKG',
  'Messkonzept 2 (MK 2)': 'Überschusseinspeisung gemäß EEG',
  'Messkonzept 2a (MK 2a)': 'Überschusseinspeisung gemäß KWKG',
  'Messkonzept 5 (MK 5)':
    'Kaufmännisch - bilanzielle Weitergabe gemäß § 11 Abs . 2 EEG',
  'Messkonzept 6 (MK 6)': 'Überschusseinspeisung mehrerer Energiearten',
  'Messkonzept 7 (MK 7)':
    'Überschusseinspeisung mehrerer Erzeugungsanlagen (Erweiterungen)',
  'Messkonzept 8 (MK 8)':
    'Erzeugungsanlage mit Haushalt und steuerbaren Verbrauchseinrichtungen',
  'Messkonzept 8a (MK 8a)':
    'Erzeugungsanlage mit Haushalt und steuerbaren Verbrauchseinrichtungen',
  'Messkonzept 8b (MK 8b)':
    'Erzeugungsanlage mit Haushalt und steuerbaren Verbrauchseinrichtungen',
};
const COMPENSATION_TYPES = {
  usual: 'usual',
  exceptional_cases: 'exceptional_cases',
  direct_marketing: 'direct_marketing',
  other_direct_marketing: 'other_direct_marketing',
  no_funding: 'no_funding',
};

export const compensationTypeOptions = [
  {
    name: COMPENSATION_TYPES.usual,
    title: i18n.t('feed_in_compensation_step.feed_in_compensation_usual'),
  },
  {
    name: COMPENSATION_TYPES.exceptional_cases,
    title: i18n.t(
      'feed_in_compensation_step.feed_in_compensation_exceptional_cases'
    ),
  },
  {
    name: COMPENSATION_TYPES.direct_marketing,
    title: i18n.t('feed_in_compensation_step.direct_marketing'),
  },
  {
    name: COMPENSATION_TYPES.other_direct_marketing,
    title: i18n.t('feed_in_compensation_step.other_direct_marketing'),
  },
  {
    name: COMPENSATION_TYPES.no_funding,
    title: i18n.t('feed_in_compensation_step.no_funding'),
  },
];

export const tenantSurchargeOptions = [
  {
    name: 'true',
    title: i18n.t('feed_in_compensation_step.tenant_power_surcharge'),
  },
  {
    name: 'false',
    title: i18n.t('feed_in_compensation_step.no_tenant_power_surcharge'),
  },
];

export const PV_SYSTEM_TYPES = {
  plug_in_pv_system: 'plug_in_photovoltaic_system',
  pv_system: 'photovoltaic_system',
};

export const PVJOURNEYS = {
  pv: 'photovoltaik',
  pvi: 'pv-inbetriebnahme',
  pvip: 'pv-inbetriebnahme-protokoll',
};

export const MESSKONZEPTE = {
  mk_1: 'Messkonzept 1 (MK 1)',
  mk_2: 'Messkonzept 2 (MK 2)',
  mk_2a: 'Messkonzept 2a (MK 2a)',
  mk_5: 'Messkonzept 5 (MK 5)',
  mk_6: 'Messkonzept 6 (MK 6)',
  mk_7: 'Messkonzept 7 (MK 7)',
  mk_8: 'Messkonzept 8 (MK 8)',
  mk_8a: 'Messkonzept 8a (MK 8a)',
  mk_8b: 'Messkonzept 8b (MK 8b)',
};

export const APPLICANT_ROLES = {
  plant_operator: 'plant_operator',
  plant_constructor: 'plant_constructor',
  service_provider: 'service_provider',
  landowner: 'landowner',
};

export const FEED_IN_STATUSES = {
  created: {
    value: 'ORDER_CREATED',
    color: '#D79BB8',
  },
  critical_created: {
    value: 'CRITICAL_ORDER_CREATED',
    color: '#D79BB8',
  },
  tech_feas: {
    value: 'ORDER_TECHNICAL_FEASIBILITY',
    color: '#CF8AA9',
  },
  confirmed: {
    value: 'ORDER_CONFIRMED',
    color: '#C7799A',
  },
  in_impl: {
    value: 'ORDER_IN_IMPLEMENTATION',
    color: '#BF678B',
  },
  in_impl_insp: {
    value: 'ORDER_IN_IMPLEMENTATION_INSPECTION',
    color: '#B7567B',
  },
  in_impl_conf: {
    value: 'ORDER_IN_IMPLEMENTATION_CONFIRMED',
    color: '#B7567B',
  },
  fulfilled: {
    value: 'ORDER_FULFILLED',
    color: '#AE436C',
  },
  fulfilled_insp: {
    value: 'ORDER_FULFILLED_INSPECTION',
    color: '#AE436C',
  },
  fulfilled_conf: {
    value: 'ORDER_FULFILLED_CONFIRMED',
    color: '#9B144C',
  },
  transmitted: {
    value: 'ORDER_TRANSMITTED',
    color: '#9b0040',
  },
  declined: {
    value: 'ORDER_DECLINED',
    color: '#525252',
  },
  cancelled: {
    value: 'ORDER_CANCELLED',
    color: 'white',
    textColor: 'black',
  },
  protocol_cancelled: {
    value: 'ORDER_PROTOCOL_CANCELLED',
    color: '#525252',
  },
};

export const CANCELLED_PSEUDO_STATUS = {
  value: 'CANCELLED_PSEUDO_STATUS',
  label: i18n.t('feed_in_statuses.CANCELLED_PSEUDO_STATUS'),
  color: '#525252',
};

export const INSTALLER_STATUSES = {
  created: {
    value: 'LICENSE_REQUEST_CREATED',
    color: '#d79bb8',
  },
  req_checking: {
    value: 'LICENSE_REQUEST_CHECKING',
    color: '#CB80A2',
  },
  active: {
    value: 'LICENSE_REQUEST_ACTIVE',
    color: '#a72f62',
  },
  active_recheck: {
    value: 'LICENSE_REQUEST_ACTIVE_RECHECK',
    color: '#a72f62',
  },
  expired: {
    value: 'LICENSE_REQUEST_EXPIRED',
    color: '#767676',
  },
  req_refused: {
    value: 'LICENSE_REQUEST_REFUSED',
    color: '#525252',
  },
};

export const ORDER_STATUSES = {
  created: {
    value: 'ORDER_CREATED',
    color: '#d79bb8',
  },
  tech_feas: {
    value: 'ORDER_TECHNICAL_FEASIBILITY',
    color: '#CB80A2',
  },
  confirmed: {
    value: 'ORDER_CONFIRMED',
    color: '#bf658d',
  },
  fulfilled: {
    value: 'ORDER_FULFILLED',
    color: '#9b144c',
  },
  manual: {
    value: 'ORDER_MANUAL',
    color: '#9b144c',
  },
  cancelled: {
    value: 'ORDER_CANCELLED',
    color: '#525252',
  },
};

export const EMOBILITY_STATUSES = {
  created: {
    value: 'ORDER_CREATED',
    color: '#d79bb8',
  },
  tech_feas: {
    value: 'ORDER_TECHNICAL_FEASIBILITY',
    color: '#CB80A2',
  },
  confirmed: {
    value: 'ORDER_CONFIRMED',
    color: '#bf658d',
  },
  cancelled: {
    value: 'ORDER_CANCELLED',
    color: '#525252',
  },
};

export const REGIONAL_TEAMS = {
  RT_VIE: {
    label: 'Regionalteam VIE',
    value: 'RT Viersen',
    team_short: 'VIE',
    regional_team_id: '0516603200014575',
    color: '#302c81',
  },
  RT_MG: {
    label: 'Regionalteam MG',
    value: 'RT Mönchengladbach',
    team_short: 'MG',
    regional_team_id: '0511600000006767',
    color: '#24478A',
  },
  RT_HS: {
    label: 'Regionalteam HS',
    value: 'RT Heinsberg',
    team_short: 'HS',
    regional_team_id: '0537000400001338',
    color: '#006CAD',
  },
  RT_RKN: {
    label: 'Regionalteam RKN',
    value: 'RT R-K-N',
    team_short: 'RKN',
    regional_team_id: '0516200800001605',
    color: '#7BBDEC',
  },
};

export const DEPLOYMENT_DATE = '2025-02-26T11:45:00.000Z';

<template>
  <div>
    <!-- Einspeisung -->
    <RowItem
      first
      class="row-item"
      field="updatePVSystemInfos"
      :title="feedInTitle"
      :editable="!hasStatusOrderTransmitted"
      @edit="
        $emit('edit-component', {
          component: 'PhotovoltaicSystemComponent',
          location: 'technicalDetailsComponent',
          properties: feedInSystem,
        })
      "
    >
      <div v-html="presentableFeedInSystem.pvSystemInfo"></div>
    </RowItem>
    <!-- Anlagenschlüssel -->
    <RowItem
      class="row-item"
      :title="$t('feed_in_system_details.pv_system_key')"
      :editable="false"
    >
      {{ feedInSystem.pv_system_key ? feedInSystem.pv_system_key : '-' }}
    </RowItem>
    <!-- Wechselrichterleistung -->
    <RowItem
      v-if="feedInSystem.pv_inverters"
      class="row-item"
      field="updatePVInverter"
      :title="$t('feed_in_system_details.inverter_performance')"
      :editable="!hasStatusOrderTransmitted"
      @edit="
        $emit('edit-component', {
          component: 'InverterModelComponent',
          location: 'technicalDetailsComponent',
          properties: feedInSystem,
        })
      "
    >
      <div
        v-for="(item, index) in feedInSystem.pv_inverters"
        :key="index"
        class="row-item__inverter"
      >
        <div class="list-item">
          <span>{{
            $t('feed_in_system_details.inverter') + ' ' + (index + 1)
          }}</span>
          <v-tooltip v-if="!item.id" left color="primary" max-width="150px">
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <component :is="IconAusrufungszeichen" />
              </div>
            </template>
            <span>{{
              $t('feed_in_system_details.inverter_not_from_list')
            }}</span>
          </v-tooltip>
        </div>
        <div class="list-item">
          <span
            >{{ $t('inverter_model_step.manufacturer') }}:
            {{ item.manufacturer }}</span
          >
        </div>
        <div class="list-item">
          <span>{{ $t('inverter_model_step.type') }}: {{ item.type }}</span>
        </div>
        <div class="list-item">
          <span
            >{{ $t('feed_in_system_details.amount') }}:
            {{ item.number_of_inverters }}</span
          >
        </div>
        <div class="list-item">
          {{ $t('feed_in_system_details.active_power') }}:
          {{ divideByThousand(item.active_power) }}
          {{ $t('feed_in_system_details.kW') }}
        </div>
        <div class="list-item">
          {{ $t('feed_in_system_details.apparent_power') }}:
          {{ divideByThousand(item.apparent_power) }}
          {{ $t('feed_in_system_details.kVA') }}
        </div>
        <div class="list-item">
          {{ $t('feed_in_system_details.rated_current') }}:
          {{ item.rated_current }}
          {{ $t('feed_in_system_details.A') }}
        </div>
        <div class="list-item">
          {{ $t('feed_in_system_details.e4_cert_number') }}:
          {{ item.e4_cert_number }}
        </div>
        <div class="list-item">
          {{ $t('feed_in_system_details.e6_cert_number') }}:
          {{ item.e6_cert_number }}
        </div>
      </div>
    </RowItem>
    <!-- Bereits bestehende Erzeugungsanlagen -->
    <RowItem
      class="row-item"
      field="updatePVExistingSystems"
      :title="$t('feed_in_system_details.existing_systems')"
      :editable="!hasStatusOrderTransmitted"
      @edit="
        $emit('edit-component', {
          component: 'ExistingSystemsComponent',
          location: 'technicalDetailsComponent',
          properties: feedInSystem,
        })
      "
    >
      <div v-if="hasExistingSystems">
        <div
          v-for="system in presentableFeedInSystem.existingSystems"
          :key="system.name"
          class="list-item"
          style="flex-wrap: wrap"
        >
          <span>{{ system.name }}</span>
          <span>
            {{ numberToGermanFormat(system.power) }} {{ system.unit }}
          </span>
          <span style="flex-basis: 100%; height: 0"> </span>
          <span>{{ system.date_label }} </span> <span>{{ system.date }}</span>
        </div>
      </div>
      <div v-if="!hasExistingSystems">
        {{ $t('feed_in_system_details.no_existing_system') }}
      </div>
    </RowItem>
    <!-- Standort der neuen Anlage -->
    <RowItem
      class="row-item"
      field="updatePVLocation"
      :title="$t('feed_in_system_details.location_of_new_system')"
      :editable="!hasStatusOrderTransmitted"
      @edit="
        $emit('edit-component', {
          component: 'SystemLocationComponent',
          location: 'technicalDetailsComponent',
          properties: feedInSystem,
        })
      "
    >
      <div>{{ pvLocation }}</div>
      <div v-if="coordinates">
        {{ $t('feed_in_system_details.coordinates') }}: {{ coordinates.lat }},
        {{ coordinates.lng }}
      </div>
    </RowItem>
    <!-- Speicher -->
    <RowItem
      class="row-item"
      field="updatePVEnergyStorage"
      :title="$t('feed_in_system_details.storage')"
      :editable="!hasStatusOrderTransmitted"
      @edit="
        $emit('edit-component', {
          component: 'StorageComponent',
          location: 'technicalDetailsComponent',
          properties: feedInSystem,
        })
      "
    >
      <div class="energy-storag-content">
        <div v-html="presentableFeedInSystem.energyStorage"></div>
        <div class="storage-tooltip">
          <v-tooltip
            v-if="
              feedInSystem.energy_storage && !feedInSystem.energy_storage.id
            "
            left
            color="primary"
            max-width="150px"
          >
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <component :is="IconAusrufungszeichen" />
              </div>
            </template>
            <span>{{
              $t('feed_in_system_details.storage_not_from_list')
            }}</span>
          </v-tooltip>
        </div>
      </div>
    </RowItem>
    <!-- MaStR Speicher -->
    <RowItem
      v-if="feedInSystem.is_storage_planned && hasProtokollStatus"
      class="row-item"
      field="updatePVStorageMaStRData"
      :title="$t('feed_in_system_details.storage_market_data_register')"
      :editable="!hasStatusOrderTransmitted"
      @edit="
        $emit('edit-component', {
          component: 'StorageMarketDataRegisterComponent',
          location: 'technicalDetailsComponent',
        })
      "
    >
      <div v-html="presentableFeedInSystem.storageMaStR"></div>
    </RowItem>
    <!-- Anlagenschlüssel Speicher -->
    <RowItem
      class="row-item"
      :title="$t('feed_in_system_details.pv_storage_key')"
      :editable="false"
    >
      {{ feedInSystem.pv_storage_key ? feedInSystem.pv_storage_key : '-' }}
    </RowItem>
    <!-- Pläne -->
    <RowItem
      class="row-item"
      field="updatePVFileNames"
      :title="$t('feed_in_system_details.plans')"
      :editable="!hasStatusOrderTransmitted"
      @edit="
        $emit('edit-component', {
          component: 'PVFileUploadComponent',
          location: 'PVFileUploadComponent',
          properties: feedInSystem,
        })
      "
    >
      <div v-for="(plan, index) in plans" :key="index">
        {{ plan }}
      </div>
    </RowItem>
    <!-- Messkonzept -->
    <RowItem
      v-if="presentableFeedInSystem.measurementConcept && !isPVAnfrage"
      class="row-item"
      field="updatePVMeasurementConcept"
      :title="$t('feed_in_system_details.measurement_concept')"
      :editable="!hasStatusOrderTransmitted"
      @edit="
        $emit('edit-component', {
          component: 'MeasureConceptComponent',
          location: 'MeasureConceptComponent',
          properties: feedInSystem,
        })
      "
    >
      <div v-html="presentableFeedInSystem.measurementConcept"></div>
      <br />
      <div class="margin-top-minus" v-html="measurementConceptSmallTitle"></div>
    </RowItem>
    <!-- feedInForecast -->
    <RowItem
      v-if="!isPVAnfrage"
      class="row-item"
      field="updatePVFeedInProspect"
      :title="$t('feed_in_system_details.forecast')"
      :editable="!hasStatusOrderTransmitted"
      @edit="
        $emit('edit-component', {
          component: 'FeedInProspectComponent',
          location: 'FeedInProspectComponent',
        })
      "
    >
      <div v-html="presentableFeedInSystem.feedInProspect"></div>
    </RowItem>
    <!-- feedInProfile -->
    <RowItem
      v-if="feedInSystem.feed_in_profile"
      class="row-item"
      :title="$t('feed_in_system_details.feed_in_profile')"
      :editable="false"
    >
      <div v-html="feedInSystem.feed_in_profile"></div>
    </RowItem>
    <!-- Einspeisemanagement - feedin_management -->
    <RowItem
      v-if="
        feedInSystem.status === feedInStatuses.fulfilled_conf.value ||
        feedInSystem.status === feedInStatuses.transmitted.value
      "
      class="row-item"
      field="updatePVFeedInManagement"
      :title="'Einspeisemanagement'"
      :editable="!hasStatusOrderTransmitted"
      @edit="
        $emit('edit-component', {
          component: 'FeedInManagementComponent',
          location: 'FeedInManagementComponent',
        })
      "
    >
      <div>
        {{
          feedInSystem.feedin_management
            ? $t(
                `feedin_management.${feedInSystem.feedin_management.toString()}`
              )
            : '-'
        }}
      </div>
      <div>
        <span>Datum ab:</span> &nbsp;
        <span>{{ formatDate(feedInSystem.implementing_date) }}</span>
      </div>
    </RowItem>
    <!-- Leistungsflussüberwachung -->
    <RowItem
      v-if="hasProtokollStatus"
      class="row-item"
      :title="$t('feed_in_system_details.power_flow_monitoring')"
      :editable="!hasStatusOrderTransmitted"
      @edit="
        $emit('edit-component', {
          component: 'PowerFlowMonitoringComponent',
          location: 'technicalDetailsComponent',
          properties: feedInSystem,
        })
      "
    >
      <div v-html="presentableFeedInSystem.powerFlowMonitoring"></div>
    </RowItem>
    <!-- NA-Schutz -->
    <RowItem
      v-if="hasProtokollStatus"
      class="row-item"
      field="updatePhotovoltaics"
      :title="$t('feed_in_system_details.system_protection')"
      :editable="!hasStatusOrderTransmitted"
      @edit="
        $emit('edit-component', {
          component: 'SystemProtectionComponent',
          location: 'SystemProtectionComponent',
          properties: {
            feedInSystemSystemProtection,
          },
        })
      "
    >
      <div v-html="presentableFeedInSystem.systemProtection"></div>
    </RowItem>
    <!-- Symmetriebedingung -->
    <RowItem
      v-if="hasProtokollStatus"
      class="row-item"
      :title="$t('feed_in_system_details.symetry_requirement')"
      :editable="!hasStatusOrderTransmitted"
      @edit="
        $emit('edit-component', {
          component: 'SymmetryRequirementComponent',
          location: 'technicalDetailsComponent',
          properties: feedInSystem,
        })
      "
    >
      <div v-html="presentableFeedInSystem.symmetryRequirement"></div>
    </RowItem>
    <!-- Blindleistungsbereitstellung -->
    <RowItem
      v-if="hasProtokollStatus"
      class="row-item"
      :last="
        !feedInSystem.permissions.perm_of_cost_radio_ripple_control_receiver &&
        !feedInSystem.permissions.perm_of_cost_remote_control_system
      "
      :title="$t('feed_in_system_details.reactive_power_provision')"
      :editable="!hasStatusOrderTransmitted"
      @edit="
        $emit('edit-component', {
          component: 'ReactivePowerProvisionComponent',
          location: 'ReactivePowerProvisionComponent',
          enableNote: true,
        })
      "
    >
      <div v-html="presentableFeedInSystem.reactivePowerProvision"></div>
    </RowItem>
    <!-- Steuereinrichtung -->
    <RowItem
      v-if="
        feedInSystem.permissions.perm_of_cost_radio_ripple_control_receiver ||
        feedInSystem.permissions.perm_of_cost_remote_control_system
      "
      class="row-item"
      last
      :title="$t('feed_in_system_details.control_device')"
      :editable="!hasStatusOrderTransmitted"
      @edit="
        $emit('edit-component', {
          component: 'ParameterSetComponent',
          location: 'ParameterSetComponent',
          enableNote: false,
        })
      "
    >
      <div v-html="controlDeviceValue"></div>
      <span class="param-title">{{
        $t('feed_in_system_details.parameter_set')
      }}</span>
      <span class="param-content" v-html="parameterSet"></span>
    </RowItem>
  </div>
</template>

<script>
import RowItem from './RowItem';
import {
  MESSKONZEPTE_SMALL_TITLES,
  PVJOURNEYS,
  FEED_IN_STATUSES,
} from '@/helpers/constants';
import { formatPVObject } from './transform_raw_data_to_pv_format';
import { germanNumberFormat } from '@/helpers/globalFunctions';
import { allIcons } from '@newnetzportal/netzportal-webapp-storybook-components';

export default {
  name: 'TechnicalDetails',
  components: {
    RowItem,
  },
  props: {
    feedInSystem: Object,
  },
  data() {
    return {
      feedInStatuses: FEED_IN_STATUSES,
    };
  },
  computed: {
    isPVAnfrage() {
      return this.feedInSystem.journey === PVJOURNEYS.pv;
    },
    hasProtokollStatus() {
      const protokollStatuses = [
        FEED_IN_STATUSES.fulfilled,
        FEED_IN_STATUSES.fulfilled_insp,
        FEED_IN_STATUSES.fulfilled_conf,
        FEED_IN_STATUSES.transmitted,
        FEED_IN_STATUSES.protocol_cancelled,
      ];

      return protokollStatuses.some(
        (status) => status.value === this.feedInSystem.status
      );
    },
    hasStatusOrderTransmitted() {
      return this.feedInSystem.status === FEED_IN_STATUSES.transmitted.value;
    },
    presentableFeedInSystem() {
      return formatPVObject(this.feedInSystem);
    },
    feedInTitle() {
      return this.feedInSystem.journey === PVJOURNEYS.pvip
        ? this.$t('feed_in_system_details.feed_in_inverter_and_powers')
        : this.$t('feed_in_system_details.feed_in_and_inverter');
    },
    hasExistingSystems() {
      return this.feedInSystem.has_existing_systems;
    },
    pvLocation() {
      const LOCATION_TYPES = {
        roof: this.$t('feed_in_system_details.system_on_roof'),
        facade: this.$t('feed_in_system_details.system_on_facade'),
        detached: this.$t('feed_in_system_details.system_detached'),
      };
      return LOCATION_TYPES[this.feedInSystem.pv_location];
    },
    coordinates() {
      return this.feedInSystem.pv_location_coordinates;
    },
    storage() {
      if (!this.feedInSystem.energy_storage) {
        return this.$t('feed_in_system_details.no');
      }
      return this.feedInSystem.energy_storage.manufacturer
        ? this.$t('feed_in_system_details.yes')
        : this.$t('feed_in_system_details.no');
    },
    plans() {
      return this.feedInSystem.file_names;
    },
    hasFeedInProspect() {
      return this.feedInSystem.feed_in_prospect_wh;
    },
    measurementConceptSmallTitle() {
      return MESSKONZEPTE_SMALL_TITLES[this.feedInSystem.measure_concept];
    },
    parameterSet() {
      return this.feedInSystem.parameter_set || '-';
    },
    controlDeviceValue() {
      const systemPowerW = this.feedInSystem.pv_system_infos.pv_system_power_W;
      const costsRadioRippleControlReceiverAccepted =
        this.feedInSystem.permissions
          .perm_of_cost_radio_ripple_control_receiver;
      const costsRemoteControlSystemAccepted =
        this.feedInSystem.permissions.perm_of_cost_remote_control_system;

      if (
        systemPowerW >= 25000 &&
        systemPowerW < 100000 &&
        costsRadioRippleControlReceiverAccepted
      ) {
        return this.$t('control_device_options.radio_controlled_receiver');
      } else if (systemPowerW >= 100000 && costsRemoteControlSystemAccepted) {
        return this.$t('control_device_options.remote_controlled_system');
      } else {
        return this.$t('control_device_options.none');
      }
    },
    IconAusrufungszeichen() {
      return allIcons.IconAusrufungszeichen;
    },
    feedInSystemSystemProtection() {
      return this.feedInSystem.system_protection;
    },
  },
  methods: {
    numberToGermanFormat(value) {
      return germanNumberFormat(value);
    },
    divideByThousand(str) {
      if (!str) return;
      return parseInt(str) / 1000;
    },
    formatDate(str) {
      const dateObj = new Date(str);

      const day = String(dateObj.getUTCDate()).padStart(2, '0');
      const month = String(dateObj.getUTCMonth() + 1).padStart(2, '0');
      const year = dateObj.getUTCFullYear();

      const formattedDate = `${day}.${month}.${year}`;
      return formattedDate;
    },
  },
};
</script>

<style scoped>
.row-item {
  margin-block: 1px;
}
.row-item__inverter {
  margin-bottom: 40px;
}
.row-item__inverter:last-child {
  margin-bottom: 0;
}
.margin-top-minus {
  margin-top: -12px;
}

.margin-bottom {
  margin-bottom: 14px;
}
</style>
<style>
.list-item {
  display: flex;
  justify-content: space-between;
  margin-right: 40px;
  padding-bottom: 5px;
  margin-bottom: 5px;
}

.list-item:last-child {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}

.param-title {
  position: relative;
  left: -43%;
  padding-left: 1px;
  width: 30%;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0;
  color: #6e6e6e;
  opacity: 1;
}
.param-content {
  position: relative;
  left: -101px;
}
.storage-tooltip {
  margin-right: 40px;
}
.energy-storag-content {
  display: flex;
  justify-content: space-between;
}
</style>

<template>
  <div>
    <div v-if="!isLoading" style="display: flex; justify-content: flex-end">
      <v-tooltip bottom color="primary">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mx-2"
            fab
            dark
            large
            color="primary"
            @click="fetchCsvData"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon dark> mdi-download </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('general.btn.download_feed_in_systems_hover') }}</span>
      </v-tooltip>
    </div>
    <div v-else>
      <v-tooltip bottom color="primary">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mx-2"
            fab
            dark
            large
            color="primary"
            @click="fetchCsvData"
            v-bind="attrs"
            v-on="on"
          >
            <v-progress-circular
              indeterminate
              color="white"
            ></v-progress-circular>
          </v-btn>
        </template>
        <span>{{ $t('general.btn.download_feed_in_systems_hover') }}</span>
      </v-tooltip>
    </div>

    <div v-if="isError" class="mt-4">
      <NewAlert color="ERROR" :message="$t('error_message.file_download')" />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { NewAlert } from '@newnetzportal/netzportal-webapp-storybook-components';
import { writeFile } from 'xlsx';
export default {
  name: 'FeedInSystemsDownloadData',
  components: {
    NewAlert,
  },
  props: {
    dateRangeFilters: {
      type: Object,
      required: true,
    },
    minPVSystemPowerW: {
      type: Number,
      required: false,
      default: null,
    },
    maxPVSystemPowerW: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      getFeedInSystemsDataUrl: process.env.VUE_APP_PV_DATA_EXPORT,
      apiKey: process.env.VUE_APP_PV_DATA_API_KEY,
      isLoading: false,
      isError: false,
    };
  },
  methods: {
    async fetchCsvData() {
      this.isLoading = true;
      try {
        const requestBody = this.getQueryBody();
        const config = {
          method: 'post',
          url: this.getFeedInSystemsDataUrl,
          headers: {
            'x-api-key': this.apiKey,
            'Content-Type': 'application/json',
          },
          data: JSON.stringify(requestBody),
        };

        const response = await axios(config);

        if (response.status !== 200) {
          throw new Error('Data could not be downloaded');
        }

        writeFile(
          response.data?.message,
          `Feed_in_Systems_Data_${new Date().toISOString()}.xlsx`
        );
      } catch (error) {
        this.isError = true;
        setTimeout(() => {
          this.isError = false;
        }, 5000);
      }
      this.isLoading = false;
    },
    getQueryBody() {
      return {
        minPVSystemPowerW: this.minPVSystemPowerW,
        maxPVSystemPowerW: this.maxPVSystemPowerW,
        status:
          this.$route.query.status === 'all' || !this.$route.query?.status
            ? 'all'
            : [this.$route.query.status],
        startDate: this.dateRangeFilters.start || null,
        endDate: this.dateRangeFilters.end || null,
      };
    },
  },
};
</script>

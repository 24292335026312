export const installersModule = {
  state: {
    installer_company_gasandwater: {},
    installer_company_electricity: {},
    allInstallers: [],
    numberOfAllInstallers: 0,
    nextToken: null,
    allInstallersLoaded: false,
    selectedInstaller: {},
    installerStatusFilter: '',
    installerEnergyTypeFilter: '',
    installerInternalFilter: '',
    installerExpiryDateFilter: '',
  },
  mutations: {
    setInstallerCompanyElectricityNameChangedObject(
      state,
      installation_company_electricity
    ) {
      this.state.changed_object.installation_company_electricity =
        installation_company_electricity;
    },
    setInstallerCompanyGasandwaterNameChangedObject(
      state,
      installation_company_gas
    ) {
      this.state.changed_object.installation_company_gas =
        installation_company_gas;
    },
    resetInstallerCompanyElectricityChangedObject() {
      this.state.changed_object.installation_company_electricity = '';
    },
    resetInstallerCompanyGasChangedObject() {
      this.state.changed_object.installation_company_gas = '';
    },
    setIsElectricityManuellInput(state, is_manuell_input) {
      state.installer_company_electricity.is_manuell_input = is_manuell_input;
    },
    setInstallerCompanyElectricityNotYetCommissioned(
      state,
      installer_company_not_yet_commissioned
    ) {
      state.installer_company_electricity.installer_company_not_yet_commissioned =
        installer_company_not_yet_commissioned;
    },
    setInstallerCompanyElectricityNotListed(
      state,
      installation_company_not_listed
    ) {
      state.installer_company_electricity.installation_company_not_listed =
        installation_company_not_listed;
    },
    setInstallerCompanyGasandwaterNotYetCommissioned(
      state,
      installer_company_not_yet_commissioned
    ) {
      state.installer_company_gasandwater.installer_company_not_yet_commissioned =
        installer_company_not_yet_commissioned;
    },
    setInstallerCompanyGasandwaterNotListed(
      state,
      installation_company_not_listed
    ) {
      state.installer_company_gasandwater.installation_company_not_listed =
        installation_company_not_listed;
    },
    setIsGasandwaterManuellInput(state, is_manuell_input) {
      state.installer_company_gasandwater.is_manuell_input = is_manuell_input;
    },
    set_electricity_zip_from_google(state, address_zip_from_google) {
      state.installer_company_electricity.address_zip_from_google =
        address_zip_from_google;
    },
    set_gasandwater_zip_from_google(state, address_zip_from_google) {
      state.installer_company_gasandwater.address_zip_from_google =
        address_zip_from_google;
    },
    setAllInstallersLoaded(state, allInstallersLoaded) {
      state.allInstallersLoaded = allInstallersLoaded;
    },
    setAllInstallers(state, allInstallers) {
      state.allInstallers = allInstallers;
    },
    setNumberOfAllInstallers(state, numberOfAllInstallers) {
      state.numberOfAllInstallers = numberOfAllInstallers;
    },
    deleteSelectedInstaller(state) {
      state.selectedInstaller = {};
    },
    setSelectedInstaller(state, selectedInstaller) {
      state.selectedInstaller = selectedInstaller;
    },
    updateSelectedInstallerData(state, newValue) {
      Object.keys(newValue).forEach((changedPart) => {
        state.selectedInstaller[changedPart] = newValue[changedPart];
      });
    },
    updateLastChangedByInSelectedInstaller(state, last_changed_by) {
      state.selectedInstaller.last_changed_by = last_changed_by;
    },
    updateLastUpdateInSelectedInstaller(state, last_update) {
      state.selectedInstaller.last_update = last_update;
    },
    setInstallerstatusFilter(state, installerStatusFilter) {
      state.installerStatusFilter = installerStatusFilter;
    },
    setInstallerenergy_typeFilter(state, installerEnergyTypeFilter) {
      state.installerEnergyTypeFilter = installerEnergyTypeFilter;
    },
    setInstallerinternalFilter(state, installerInternalFilter) {
      state.installerInternalFilter = installerInternalFilter;
    },
    setInstallerexpiryFilter(state, installerExpiryDateFilter) {
      state.installerExpiryDateFilter = installerExpiryDateFilter;
    },
  },
  getters: {
    getIsGasandwaterManuellInput: (state) => {
      return state.installer_company_gasandwater.is_manuell_input;
    },
    getIsElectricityManuellInput: (state) => {
      return state.installer_company_electricity.is_manuell_input;
    },
    get_gasandwater_zip_from_google: (state) => {
      return state.installer_company_gasandwater.address_zip_from_google;
    },
    get_electricity_zip_from_google: (state) => {
      return state.installer_company_electricity.address_zip_from_google;
    },
    getProductInstallationAddressZip: (state, getters, rootState) => {
      const selectedOrder = rootState.orders.selectedOrder;
      return selectedOrder.product_installation_full_address
        ? selectedOrder.product_installation_full_address.zip
        : selectedOrder.product_installation_with_plot_number_address.zip;
    },
    getAllInstallersLoaded(state) {
      return state.allInstallersLoaded;
    },
    getAllInstallers(state) {
      return state.allInstallers;
    },
    getNumberOfAllInstallers(state) {
      return state.numberOfAllInstallers;
    },
    getInstallersNextToken(state) {
      return state.nextToken;
    },
    getSelectedInstaller(state) {
      return state.selectedInstaller;
    },
    getInstallerstatusFilter(state) {
      return state.installerStatusFilter;
    },
    getInstallerenergy_typeFilter(state) {
      return state.installerEnergyTypeFilter;
    },
    getInstallerinternalFilter(state) {
      return state.installerInternalFilter;
    },
    getInstallerexpiryFilter(state) {
      return state.installerExpiryDateFilter;
    },
    getOldInstallerInspectionData: (state) => (internal) => {
      let data = {};
      if (internal) {
        data = {
          company_data_checked_electricity:
            state.selectedInstaller.company_data_checked_electricity,
          responsible_specialists_checked_electricity:
            state.selectedInstaller.responsible_specialists_checked_electricity,
          documents_checked_electricity:
            state.selectedInstaller.documents_checked_electricity,
          professional_competence_checked_electricity:
            state.selectedInstaller.professional_competence_checked_electricity,
          company_data_checked_gas_water:
            state.selectedInstaller.company_data_checked_gas_water,
          responsible_specialists_checked_gas_water:
            state.selectedInstaller.responsible_specialists_checked_gas_water,
          documents_checked_gas_water:
            state.selectedInstaller.documents_checked_gas_water,
          professional_competence_checked_gas_water:
            state.selectedInstaller.professional_competence_checked_gas_water,
        };
      } else {
        data = {
          company_data_checked_electricity:
            !!state.selectedInstaller.company_data_checked_electricity,
          responsible_specialists_checked_electricity:
            !!state.selectedInstaller
              .responsible_specialists_checked_electricity,
          installer_id_checked_electricity:
            !!state.selectedInstaller.installer_id_checked_electricity,
          company_data_checked_gas_water:
            !!state.selectedInstaller.company_data_checked_gas_water,
          responsible_specialists_checked_gas_water:
            !!state.selectedInstaller.responsible_specialists_checked_gas_water,
          installer_id_checked_gas_water:
            !!state.selectedInstaller.installer_id_checked_gas_water,
          expiry_date_electricity:
            state.selectedInstaller.expiry_date_electricity,
          expiry_date_water: state.selectedInstaller.expiry_date_water,
          expiry_date_gas: state.selectedInstaller.expiry_date_gas,
        };
      }
      return data;
    },
  },
  actions: {},
};

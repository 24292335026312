var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"full_width"},[_c('v-btn',{staticClass:"refresh-button",on:{"click":function($event){return _vm.refreshOrders()}}},[_c('v-icon',{attrs:{"color":"#6E6E6E"}},[_vm._v("mdi-sync")])],1),_c('div',{staticClass:"table-style"},[_c('div',{staticClass:"table-header"},[_c('div',{staticClass:"statuses-and-download"},[_c('div',{staticClass:"pill-radio-buttons-div"},[_c('PillRadioButtons',{attrs:{"selectedValue":_vm.statusSelection,"itemsList":_vm.computedOrderFilters},on:{"update-selected-value":function($event){_vm.statusSelection = $event}}})],1),_c('FeedInSystemsDownloadData',{attrs:{"dateRangeFilters":_vm.dateFilters,"minPVSystemPowerW":_vm.minPVSystemPowerW,"maxPVSystemPowerW":_vm.maxPVSystemPowerW}})],1),_c('div',{staticClass:"pill-radio-buttons-div"},[_c('PillRadioButtons',{attrs:{"selectedValue":_vm.pVPerformanceSelection,"itemsList":_vm.performanceValuesList},on:{"update-selected-value":function($event){_vm.pVPerformanceSelection = $event}}})],1),[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"flex-column flex-sm-row align-center"},[_c('v-col',{staticClass:"mb-4 mr-8 mb-sm-0 pr-sm-10",staticStyle:{"max-width":"700px"},attrs:{"cols":"12","sm":""}},[_c('v-text-field',{attrs:{"rounded":"","outlined":"","dense":"","full-width":"","prepend-inner-icon":"mdi-magnify","label":"Suche","single-line":"","hide-details":""},on:{"input":_vm.elasticSearchOnBackend},model:{value:(_vm.searchValue),callback:function ($$v) {_vm.searchValue=$$v},expression:"searchValue"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"auto"}},[_c('PeriodDatePicker',{on:{"date-change":_vm.onDateChange}})],1)],1)],1)]],2),_c('v-data-table',{attrs:{"disable-sort":"","headers":_vm.headersAktive,"items":_vm.activeOrdersDisplayed,"item-key":"orderId","loading":_vm.loading,"loading-text":_vm.$t('overview_table.loading_text'),"no-data-text":this.isDataLoaded
          ? _vm.$t('overview_table.no_filter_data')
          : _vm.$t('overview_table.error_data'),"footer-props":{
        'items-per-page-options': [10],
      },"server-items-length":_vm.filteredActiveOrdersCount,"calculate-widths":"","options":_vm.options,"page":_vm.currentPage},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(item.date,'DD.MM.YYYY HH:mm')))])]}},{key:"item.status",fn:function(props){return [_c('div',{staticClass:"flex-row-status"},[_c('v-btn',{style:({
              //color here is for text color
              color: props.item.status.textColor
                ? props.item.status.textColor
                : 'white',
              pointerEvents: 'none',
            }),attrs:{"rounded":"","dark":"","color"://color here is for background-color
              props.item.status ? props.item.status.color : ''}},[_vm._v(_vm._s(props.item.status ? props.item.status.label : ''))]),(
              props.item.status.value === _vm.FEED_IN_STATUSES.fulfilled.value &&
              _vm.isDateOlderThanThreeDays(props.item.date)
            )?_c('v-tooltip',{attrs:{"top":"","color":"primary","max-width":"230px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"ml-4 flex-center-align"},'div',attrs,false),on),[_c(_vm.IconAusrufungszeichen,{tag:"component"})],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('feed_in_systems.order_fulfilled_overdue')))])]):_vm._e(),(
              props.item.status.value ===
              _vm.FEED_IN_STATUSES.critical_created.value
            )?_c('v-tooltip',{attrs:{"top":"","color":"primary","max-width":"150px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"ml-4 flex-center-align"},'div',attrs,false),on),[_c(_vm.IconAusrufungszeichen,{tag:"component"})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('feed_in_systems.active_order_exists')))])]):_vm._e()],1)]}},{key:"item.edit",fn:function(props){return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.showDetails(props.item.orderId)}}},[_c('v-icon',[_vm._v("mdi-pencil-box-outline")])],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }